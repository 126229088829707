import React, { useMemo } from 'react';
import { useSubscriptionStatus } from '../../../../hooks/useSubscriptionStatus';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SpacingContainer } from '../../Containers';
import Grid from '@material-ui/core/Grid';
import { useTrans } from '../../../../services/i18n';
import connect from '../connect';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Typography from '../../Typography';
import premiumIcon from '../../../assests/premium-icon.png';
import { Link, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ROUTE_BILLING, ROUTE_PLANS_PRIVATE } from '../../../../constants';
import { TIERS } from '../../../../utils/subscription';
import UpgradeSubscriptionModal from '../UpgradeSubscriptionModal';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#112159',
    borderRadius: '12px',
    height: 'auto',
    padding: 15,
    width: '100%',
  },
  iconPublic: {
    color: '#ffffff',
    width: '20px',
    height: '18px',
  },
  textCenter: {
    textAlign: 'center',
  },
  smallText: {
    whiteSpace: 'normal',
  },
  boldText: {
    fontWeight: '500',
  },
});

const MediumBanner = React.memo(
  ({
    subscription,
    showSmallText = true,
    plans,
    orgSettings,
    dismissUpgradingSubscription,
    customTitle,
    customSmallText,
  }) => {
    const { pending, data } = useSubscriptionStatus(subscription);
    const { freeTrialDaysLeft, trialExpired, associatedSalesforceId } = orgSettings;
    const { trans } = useTrans();
    const classes = useStyles();
    const [openUpgradeSubscriptionModal, , onCloseSubscriptionModal] = useBooleanHandlers(trialExpired);

    const bannerPathnameLink = useMemo(() => {
      return ROUTE_PLANS_PRIVATE.path;
    }, [associatedSalesforceId]);

    if (pending) {
      return null;
    }

    const closeSubscriptionModal = () => {
      dismissUpgradingSubscription();
      onCloseSubscriptionModal();
    };

    const shouldShowAlternative = !!freeTrialDaysLeft && data.currentPlan === TIERS.FREE_TRIAL;
    const containerStyle = shouldShowAlternative ? { backgroundColor: '#17B471' } : {};
    const bannerText = shouldShowAlternative ? (
      <>
        You have <span className={classes.boldText}>{freeTrialDaysLeft} days left</span> on your Free Pro Trial
      </>
    ) : (
      trans('check-other-plans')
    );

    return (
      <Box width={'100%'}>
        <UpgradeSubscriptionModal open={openUpgradeSubscriptionModal} onClose={closeSubscriptionModal} />

        <Link to={bannerPathnameLink} style={{ textDecoration: 'none' }}>
          <SpacingContainer className={classes.container} style={containerStyle}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={2} className={classes.textCenter}>
                <img src={premiumIcon} alt="" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="white" fontFamily="lexend" weight="500">
                  {customTitle ? customTitle : trans(data.currentPlan)}
                </Typography>
                {showSmallText && (
                  <Typography
                    variant="caption"
                    component="p"
                    color="white"
                    fontFamily="lexend"
                    weight="300"
                    className={classes.smallText}
                  >
                    {customSmallText ?? bannerText}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} className={classes.textCenter}>
                <ArrowForwardIos className={classes.iconPublic} />
              </Grid>
            </Grid>
          </SpacingContainer>
        </Link>
      </Box>
    );
  }
);

export default connect(MediumBanner);
