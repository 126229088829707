import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import PlanCard from './PlanCard';
import { useIsMobile } from '../../../hooks/useIsMobile';
import MobileStepper from './MobileStepper';

function PlanList({ plans = [] }) {
  const isMobile = useIsMobile();
  const [planIndex, setPlanIndex] = useState(0);

  return (
    <>
      <Grid container justifyContent="space-between" width="100%" spacing={2}>
        {plans?.map((plan, index) => (
          <Grid item xs={12} md={12 / plans.length}>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <PlanCard key={index} {...plan} isMobile={isMobile} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default PlanList;
