import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { SCOPE_NOTIFICATIONS, API } from '../../constants';
import { INITIAL_CHECKED_ITEMS_STATE, INITIAL_LIST_STATE } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithNotInterestedActions,
  generateListActions,
  generateCheckItemsActions,
} from '../helpers/listActionsHelpers';
import { application } from '../../services/application';
import { emptyArray } from '../../utils/arrayUtils';
import { extendBuilderWithAsyncAction, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  ...INITIAL_CHECKED_ITEMS_STATE,
  pagination: { page: 1, perPage: 20 },
  order: ['updatedAt', 'desc'],

  items: [],
  count: 20,
  unReadCount: 0,
  settings: null,
  settingsPending: false,
  banner: {},
};

const listActions = generateListActions({
  scope: SCOPE_NOTIFICATIONS,
  apiMethod: {
    GET_LIST: API.NOTIFICATIONS.GET_LIST,
  },
  getStore: (store) => store.notifications,
});

const checkedItemsActions = generateCheckItemsActions({ scope: SCOPE_NOTIFICATIONS });

function getCheckedIds(store) {
  const { checkedItems = [] } = store.notifications;
  return checkedItems;
}

const markAsRead = createAsyncThunk('notifications/markAsRead', (ids, { getState }) =>
  application.call(API.NOTIFICATIONS.MARK_AS_READ, { ids: emptyArray(ids) ? getCheckedIds(getState()) : ids })
);

const markAsReadSingle = createAsyncThunk('notifications/markAsReadSingle', (params) =>
  application.call(API.NOTIFICATIONS.MARK_AS_READ_SINGLE, params)
);

const markAsTrashed = createAsyncThunk('notifications/markAsTrashed', (_, { getState }) =>
  application.call(API.NOTIFICATIONS.MARK_AS_TRASHED, { ids: getCheckedIds(getState()) })
);

const markAsRestored = createAsyncThunk('notifications/markAsRestored', (_, { getState }) =>
  application.call(API.NOTIFICATIONS.MARK_AS_RESTORED, { ids: getCheckedIds(getState()) })
);

const remove = createAsyncThunk('notifications/remove', (_, { getState }) =>
  application.call(API.NOTIFICATIONS.REMOVE, { ids: getCheckedIds(getState()) })
);

const markAllTrash = createAsyncThunk('notifications/markAllTrash', (params) =>
  application.call(API.NOTIFICATIONS.MARK_ALL_TRASH, params)
);

const restoreAll = createAsyncThunk('notifications/restoreAll', (params) =>
  application.call(API.NOTIFICATIONS.RESTORE_ALL, params)
);

const deleteAll = createAsyncThunk('notifications/deleteAll', (params) =>
  application.call(API.NOTIFICATIONS.DELETE_ALL, {})
);

const getSettings = createAsyncThunk('notifications/getSettings', () => {
  return application.call(API.NOTIFICATIONS.GET_SETTINGS);
});

const setSettings = createAsyncThunk('notifications/setSettings', (params) => {
  return application.call(API.NOTIFICATIONS.SET_SETTINGS, params);
});

const getUnread = createAsyncThunk('notifications/getUnread', (params) => {
  return application.call(API.NOTIFICATIONS.GET_UNREAD, params);
});

const getBanner = createAsyncThunk('notifications/getBanner', (params) => {
  //return { message: "This site has been hacked...", severity: "info", createdDate: new Date().getTime(), wasClosed: false };
  return application.call(API.BANNER.GET_BANNER, params);
});

function onGetBanner(state, action) {
  return {
    ...state,
    banner: action,
  };
}

function onGetUnread(state, action) {
  Object.assign(state, {
    unReadCount: action.payload,
  });
}

function settingsPending(state) {
  return {
    ...state,
    settingsPending: true,
  };
}

function onGetNotificationSettings(state, action) {
  return {
    ...state,
    settingsPending: false,
    settings: action.payload,
  };
}

export const actions = {
  ...listActions,
  ...checkedItemsActions,
  getSettings,
  setSettings,
  markAsRead,
  markAsTrashed,
  markAsRestored,
  remove,
  getUnread,
  getBanner,
  markAllTrash,
  restoreAll,
  deleteAll,
  markAsReadSingle,
};

function onSuccess(state, action) {
  console.log(action);
  //if (action.payload) successAlert('Success');
  return onPendingDone(state);
}

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, actions);
  extendBuilderWithNotInterestedActions(builder, actions);
  extendBuilderWithCheckNotificationsActions(builder, checkedItemsActions);

  extendBuilderWithAsyncAction(builder, markAsRead, { onSuccess });
  extendBuilderWithAsyncAction(builder, markAsTrashed, { onSuccess });
  extendBuilderWithAsyncAction(builder, markAsRestored, { onSuccess });
  extendBuilderWithAsyncAction(builder, remove, { onSuccess });

  return builder
    .addCase(getSettings.pending, settingsPending)
    .addCase(getSettings.fulfilled, onGetNotificationSettings)
    .addCase(setSettings.pending, settingsPending)
    .addCase(setSettings.fulfilled, onGetNotificationSettings)
    .addCase(getUnread.fulfilled, onGetUnread)
    .addCase(getBanner.fulfilled, onGetBanner);
});

function extendBuilderWithCheckNotificationsActions(builder, actions, getStore) {
  const { checkItem, checkAllItems, removeAllItems } = actions;
  const getStoreValues = getStore ? getStore : (state) => state;
  const filters = {
    filterUnread: (item) => !item.read,
    filterRead: (item) => !!item.read,
  };

  if (checkItem) {
    builder.addCase(checkItem, (state, action) => {
      const store = getStoreValues(state);
      const { item } = action.payload;
      const index = store.checkedItems.findIndex((checkedId) => checkedId === item.id);

      if (index >= 0) {
        store.checkedItems.splice(index, 1);
      } else {
        store.checkedItems.push(item.id);
      }
    });
  }

  if (checkAllItems) {
    builder.addCase(checkAllItems, (state, { payload }) => {
      const filterCB = payload && payload.filterCB;
      const store = getStoreValues(state);
      if (emptyArray(store.items)) return;

      const items =
        filterCB && typeof filters[filterCB] === 'function'
          ? store.items.reduce((acc, { items: notifications, ...rest }) => {
              const newItems = notifications.filter(filters[filterCB]);
              return newItems.length ? [...acc, { ...rest, items: newItems }] : acc;
            }, [])
          : store.items;

      store.checkedItems = items.reduce(
        (acc, { items: notifications }) => {
          notifications.forEach((item) => {
            if (!acc.includes(item.id)) acc.push(item.id);
          });
          return acc;
        },
        Array.isArray(store.checkedItems) ? store.checkedItems : []
      );
    });
  }

  if (removeAllItems) {
    builder.addCase(removeAllItems, (state, _action) => {
      const store = getStoreValues(state);
      const items = store.items;
      if (emptyArray(store.items) || emptyArray(store.checkedItems)) return;

      items.forEach(({ items: notifications }) => {
        notifications.forEach((item) => {
          const index = store.checkedItems.findIndex((checkedId) => checkedId === item.id);
          if (index >= 0) store.checkedItems.splice(index, 1);
        });
      });
    });
  }

  return builder;
}
