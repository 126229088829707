import { connect } from 'react-redux';
import { orgActions } from '../../../../store/orgSettings';
import { changeCurrentOnboardingStep } from '../../../../store/auth';
import { application } from '../../../../services/application';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
    settings: state.orgSettings,
    currentOnboardingStep: state.auth.currentOnboardingStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: () => dispatch(orgActions.getOrganizationInfoAction({})),
    changeCurrentOnboardingStep: (params) => dispatch(changeCurrentOnboardingStep(params)),
    getAvatarDownloadUrl: (id) => {
      return application.call('admin.partnership.getUploadLogoUrl', { id });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
