import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../../../components/common/Typography';
import { Grid } from '@material-ui/core';
import { IconCloseHelpCenter, IconHelpCenterGray, IconHelpCenterWhite } from '../../../../components/svg';
import { useTrans } from '../../../../services/i18n';

const useStyles = makeStyles({
  initialContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'start',
  },
  helpCenterText: {
    color: '#888FA9',
    marginLeft: '0.3rem',
  },
  descriptionText: {
    color: '#08253E',
    marginTop: '0.3rem',
  },
  clickGetInfo: {
    color: '#4E5978',
    marginTop: '0.3rem',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  textAlignLeft: {
    textAlign: 'left',
    marginLeft: '1.8rem',
  },
  main: {
    maxHeight: '14rem',
    overflowY: 'scroll',
  },
});

export default function HelpCenter(props) {
  const { fieldHelpCenter, setFieldHelpCenter } = props;
  const { trans } = useTrans();

  const classes = useStyles();

  /***
   *
   */
  useEffect(() => {
    const { fieldDescription, fieldName } = fieldHelpCenter;

    if (fieldDescription === '') {
      const newFieldDescription = `
        <div style="font-size: 0.938rem; color: #000000;">
          <p style="font-weight: 600;">${fieldName}</p>
          <p style="font-weight: 400;">${trans('no-further-details-for-this-field')}</p>
        </div>
      `;

      if (fieldHelpCenter.fieldDescription !== newFieldDescription) {
        setFieldHelpCenter({
          fieldDescription: newFieldDescription,
          fieldName: fieldName,
        });
      }
    }
  }, [fieldHelpCenter, trans, setFieldHelpCenter]);

  return (
    <>
      {fieldHelpCenter.fieldDescription === null ? (
        <Grid container className={classes.main}>
          <Grid item xs={12} className={classes.initialContainer}>
            <IconHelpCenterWhite />
            <Typography weight="600" fontFamily="manrope" variant="subtitle1" className={classes.helpCenterText}>
              {trans('help-center')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.initialContainer}>
            <Typography weight="400" fontFamily="manrope" variant="body2" className={classes.clickGetInfo}>
              {trans('click-on-the-field-to-get-information')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.main}>
          <Grid item xs={12} className={classes.flexBetween}>
            <div className={classes.container}>
              <IconHelpCenterGray />
              <Typography weight="600" fontFamily="manrope" variant="subtitle1" className={classes.helpCenterText}>
                {trans('help-center')}
              </Typography>
            </div>
            <div
              className={classes.cursorPointer}
              onClick={() => setFieldHelpCenter({ fieldName: null, fieldDescription: null })}
            >
              <IconCloseHelpCenter />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <Typography weight="400" fontFamily="manrope" variant="body2" className={classes.descriptionText}>
              <div className={classes.textAlignLeft}>
                <div dangerouslySetInnerHTML={{ __html: fieldHelpCenter.fieldDescription }} />
              </div>
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
