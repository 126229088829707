import React, { useState, useEffect } from 'react';
import Typography from '../../../../../components/common/Typography';
import Link from '@material-ui/core/Link';
import { useTrans } from '../../../../../services/i18n';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const TOS = 'abae4562-b0ba-42d2-9277-a4e136c53f05';
const PRIVACY_POLICY = '8a58ba8a-2631-4558-8105-9cb526d9305f';
const COOKIE_POLICY = '031cee16-f717-40a3-957b-e90d54037c72';
const RETURN = '7d297e7e-16d9-4c66-8e32-a6c83f270c1c';
const DISCLAIMER = 'db997f46-428c-43e7-9ce9-0247d511ad1d';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TermsAndConditions = ({ style, textProps = {} }) => {
  const [open, setOpen] = useState(false);

  const { trans } = useTrans();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, [open]);

  return (
    <>
      <div style={style}>
        <Link
          style={{ width: '100%' }}
          onClick={() => {
            setOpen(true);
            forceUpdate();
          }}
        >
          <Typography variant="caption" weight="400" {...textProps}>
            {trans('terms-conditions')}
          </Typography>
        </Link>
      </div>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Terms & conditions
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <div name="termly-embed" data-id={TOS} data-type="iframe"></div>
          <div name="termly-embed" data-id={PRIVACY_POLICY} data-type="iframe"></div>
          <div name="termly-embed" data-id={COOKIE_POLICY} data-type="iframe"></div>
          <div name="termly-embed" data-id={RETURN} data-type="iframe"></div>
          <div name="termly-embed" data-id={DISCLAIMER} data-type="iframe"></div>
        </div>
      </Dialog>
    </>
  );
};

export default TermsAndConditions;
