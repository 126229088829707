import React from 'react';
import { Paper, SpacingContainer } from '../../../../common/Containers';
import Typography from '../../../../common/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  blueText: {
    color: theme.palette.primary.main,
  },
}));

function Warning() {
  const classes = useStyles();

  return (
    <Paper
      bgColor="warning"
      component={SpacingContainer}
      py={2}
      px={2}
      radius="4"
      style={{ display: 'flex', marginTop: '1rem' }}
    >
      <Typography variant="body2" weight={600}>
        A <span className={classes.blueText}>specialist</span> will reach back out after reviewing your request with{' '}
        <span className={classes.blueText}>a price you can pay to have this opportunity written for you.</span>
        <br />
        <br />
        The specialist may also have other questions that you will need to answer.
      </Typography>
    </Paper>
  );
}

export default Warning;
