import { Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { CheckBox, Switch } from '../../../../components/common/Inputs';
import Typography from '../../../../components/common/Typography';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';
import { useForm } from '../../../../hooks/useForm';
import { useApplication } from '../../../../services/application';
import { useTrans } from '../../../../services/i18n';
import { catchYupErrors, schemaToInitialState } from '../../../../utils/validationUtils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAlertContext } from '../../../../contexts/AlertContext';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { Button } from '../../../../components/common/Buttons';
import { ModalDialog } from '../../../../components/common/Dialogs';
import { IconSMSNotification } from '../../../../components/svg';

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    consolidateNotificationNumber: Yup.number()
      .positive(trans('PositiveNumber'))
      .integer(trans('IntegerNumber'))
      .required(),
    consolidateNotificationSelect: Yup.string(),
    pursuitNotices: Yup.bool(),
    savedSearchNotices: Yup.bool(),
    teamUpdates: Yup.bool(),
    onEmailNotifications: Yup.bool(),
    taskReminders: Yup.bool(),
    // Recap-email
    pursuitDailyRecap: Yup.bool(),
    savedSearchDailyRecap: Yup.bool(),
    teamUpdatesDailyRecap: Yup.bool(),
    taskRemindersDailyRecap: Yup.bool(),
  })
);

const useStyles = makeStyles((theme) => ({
  containerCard: {
    padding: '0rem 0rem',
    width: '96%',
  },
  containerFooter: {
    padding: '0rem 2.3rem 1.87rem 0rem',
    marginTop: 'unset',
  },
  noTopPadding: {
    paddingTop: 'unset',
    paddingBottom: 'unset',
  },
  checksContainer: {
    marginLeft: '3.2rem',
  },
  forMargin: {
    marginLeft: '0.6rem',
  },
  includeMargin: {
    marginTop: 'unset',
    marginLeft: '0.6rem',
  },
  divider: {
    marginTop: '0.5rem',
  },
  dailyRecapList: {
    paddingBottom: '0rem',
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function EmailNotificationModal({ user, onUpdate, open, onClose }) {
  const isMobile = useIsMobile('xs');
  const { trans } = useTrans();
  const schema = useScheme(trans);
  const application = useApplication();
  const [pending, setPending] = React.useState(false);
  const [savedSearchTypeNotices, setSavedSearchTypeNotices] = React.useState('instant');
  const [step, setStep] = useState(1);
  const [notificationFrequency, setNotificationFrequency] = useState('hour');
  const [notificationNumber, setNotificationNumber] = useState(1);
  const styles = useStyles();
  const { successAlert } = useAlertContext();
  // Switch for "Update Notifications"
  const [updateNoti, setUpdateNoti] = useState(false);
  // Switch for "Daily-recpa" emails
  const [dailyRecapEmails, setDailyRecapEmails] = useState(false);

  /***
   * According to user data regarding notifications,
   * change the switches values
   */
  useEffect(() => {
    if (Boolean(user?.savedSearchNotices) || Boolean(user?.taskReminders)) {
      setUpdateNoti(true);
    }

    if (
      Boolean(user?.pursuitDailyRecap) ||
      Boolean(user?.savedSearchDailyRecap) ||
      Boolean(user?.teamUpdatesDailyRecap) ||
      Boolean(user?.taskRemindersDailyRecap)
    ) {
      setDailyRecapEmails(true);
    }
  }, [user]);

  /***
   * When user clicks on "Save" button
   */
  const onSubmit = React.useCallback(
    (values, { setStatus, setErrors }) => {
      setPending(true);
      application
        .call('user.setEmailNotificationSettings', {
          ...values,
          pursuitNotices: values.pursuitNotices ? 1 : 0,
          savedSearchNotices: values.savedSearchNotices ? 1 : 0,
          teamUpdates: values.teamUpdates ? 1 : 0,
          savedSearchTypeNotices: savedSearchTypeNotices,
          consolidateNotificationSelect: notificationFrequency,
          consolidateNotificationNumber: notificationNumber,
          taskReminders: values.taskReminders ? 1 : 0,
          pursuitDailyRecap: values.pursuitDailyRecap ? 1 : 0,
          savedSearchDailyRecap: values.savedSearchDailyRecap ? 1 : 0,
          teamUpdatesDailyRecap: values.teamUpdatesDailyRecap ? 1 : 0,
          taskRemindersDailyRecap: values.taskRemindersDailyRecap ? 1 : 0,
        })
        .then((result) => {
          successAlert(trans('notifications-updated-successfully'));
          onUpdate(result);
          onClose();
        })
        .catch((e) => catchYupErrors(e, setErrors, (err) => setStatus({ message: err.message })))
        .finally(() => {
          setPending(false);
        });
    },
    [application, onClose, onUpdate, savedSearchTypeNotices, notificationFrequency, notificationNumber]
  );

  const { setValues, getFieldProps, handleSubmit, values } = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  /***
   *
   */
  React.useEffect(() => {
    setFormValues();
  }, [user, setValues]);

  /***
   * If user select al least one of the options, turn on the switch
   * If user unselect the two options, turn off the switch
   */
  useEffect(() => {
    setUpdateNoti(values?.savedSearchNotices || values?.taskReminders);
  }, [values]);

  /***
   * If user select al least one of the options, turn on the switch
   * If user unselect the two options, turn off the switch
   */
  useEffect(() => {
    setDailyRecapEmails(
      values?.pursuitDailyRecap ||
        values?.savedSearchDailyRecap ||
        values?.teamUpdatesDailyRecap ||
        values?.taskRemindersDailyRecap
    );
  }, [values]);

  /***
   * Set the form with the user values
   */
  const setFormValues = () => {
    setValues({
      consolidateNotificationNumber: 1,
      consolidateNotificationSelect: 'hour',
      pursuitNotices: Boolean(user?.pursuitNotices) || false,
      savedSearchNotices: Boolean(user?.savedSearchNotices) || false,
      teamUpdates: Boolean(user?.teamUpdates) || false,
      onEmailNotifications: Boolean(user?.onEmailNotifications) || true,
      taskReminders: Boolean(user?.taskReminders) || false,
      //Daily-recap
      pursuitDailyRecap: Boolean(user?.pursuitDailyRecap) || false,
      savedSearchDailyRecap: Boolean(user?.savedSearchDailyRecap) || false,
      teamUpdatesDailyRecap: Boolean(user?.teamUpdatesDailyRecap) || false,
      taskRemindersDailyRecap: Boolean(user?.taskRemindersDailyRecap) || false,
    });

    setSavedSearchTypeNotices('instant');
    setNotificationFrequency('hour');
    setNotificationNumber(1);
    // No Help notification modal
    setStep(2);
  };

  /***
   *
   */
  const onChangeUpdateNoti = (switchValue) => {
    // Set values according to switch value
    setValues({
      ...values,
      consolidateNotificationNumber: 1, // Now, always the time window is 1 hour
      consolidateNotificationSelect: 'hour', // Now, always the time window is 1 hour
      pursuitNotices: true, // Always receive this kind of notification
      savedSearchNotices: switchValue ? true : false, // It indicates whether emails are received or not
      teamUpdates: true, // Always receive this kind of notification
      onEmailNotifications: true,
      taskReminders: switchValue ? true : false, // It indicates whether emails are received or not
    });
  };

  /***
   *
   */
  const onChangeDailyRecap = (switchValue) => {
    // Set values according to switch value
    setValues({
      ...values,
      pursuitDailyRecap: switchValue ? true : false,
      savedSearchDailyRecap: switchValue ? true : false,
      teamUpdatesDailyRecap: switchValue ? true : false,
      taskRemindersDailyRecap: switchValue ? true : false,
    });
  };

  const handleCloseForm = () => {
    onClose();
    setFormValues();
  };

  const isChecked = true;

  return (
    <ModalDialog
      open={open}
      onClose={handleCloseForm}
      title={
        <>
          <div className={styles.modalTitle}>
            <IconSMSNotification />
          </div>
          <div className={styles.modalTitle} style={{ fontSize: isMobile ? '15px' : '21px' }}>
            {trans('email-notification-settings')}
          </div>
        </>
      }
      direction={isMobile ? 'up' : 'down'}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={pending} onClick={handleCloseForm}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={pending}
              pending={pending}
              onClick={() => {
                handleSubmit();
              }}
            >
              {pending ? trans('Saving') : trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <React.Fragment>
        <Grid container className={styles.containerCard}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.noTopPadding}>
            <List className={styles.noTopPadding}>
              <ListItem disableGutters className={styles.noTopPadding}>
                <Switch checked={updateNoti} onChange={(ev) => onChangeUpdateNoti(ev.target.checked)} />
                <ListItemText>
                  <Typography variant="h6" weight="600" color={!isChecked ? 'disabled' : 'initial'}>
                    {trans('update-notifications')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <Grid container lg={12} md={12} xs={12} className={styles.checksContainer}>
                <Grid lg={12} md={12} xs={12}>
                  <Typography variant="subtitle1" weight="600" color={'disabled'} className={styles.forMargin}>
                    {trans('include')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={5}>
                  <Typography style={{ fontSize: isMobile ? '12px' : '13px' }} weight="400">
                    <CheckBox
                      color="secondary"
                      checked={values.savedSearchNotices}
                      {...getFieldProps('savedSearchNotices')}
                    />
                    {trans('saved-searches')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={6}>
                  <Typography style={{ fontSize: isMobile ? '12px' : '13px' }} weight="400">
                    <CheckBox color="secondary" checked={values.taskReminders} {...getFieldProps('taskReminders')} />
                    {trans('task-reminders')}
                  </Typography>
                </Grid>
              </Grid>
            </List>

            <Divider light className={styles.divider} />

            <List>
              <ListItem disableGutters className={styles.dailyRecapList}>
                <Switch checked={dailyRecapEmails} onChange={(ev) => onChangeDailyRecap(ev.target.checked)} />
                <ListItemText>
                  <Typography variant="h6" weight="600" color={!isChecked ? 'disabled' : 'initial'}>
                    {trans('daily-recap-emails')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <Grid container lg={12} md={12} xs={12} className={styles.checksContainer}>
                <Grid lg={12} md={12} xs={12}>
                  <Typography variant={'subtitle1'} weight="600" color={'disabled'} className={styles.includeMargin}>
                    {trans('include')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={5}>
                  <Typography style={{ fontSize: isMobile ? '12px' : '13px' }} weight="400">
                    <CheckBox
                      color="secondary"
                      checked={values.pursuitDailyRecap}
                      {...getFieldProps('pursuitDailyRecap')}
                    />
                    {trans('pursuit-notices')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={6}>
                  <Typography style={{ fontSize: isMobile ? '12px' : '13px' }} weight="400">
                    <CheckBox
                      color="secondary"
                      checked={values.savedSearchDailyRecap}
                      {...getFieldProps('savedSearchDailyRecap')}
                    />
                    {trans('saved-searches')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={5}>
                  <Typography style={{ fontSize: isMobile ? '12px' : '13px' }} weight="400">
                    <CheckBox
                      color="secondary"
                      checked={values.teamUpdatesDailyRecap}
                      {...getFieldProps('teamUpdatesDailyRecap')}
                    />
                    {trans('team-updates')}
                  </Typography>
                </Grid>
                <Grid lg={6} md={6} sm={6} xs={6}>
                  <Typography style={{ fontSize: '12px' }} weight="400">
                    <CheckBox
                      color="secondary"
                      checked={values.taskRemindersDailyRecap}
                      {...getFieldProps('taskRemindersDailyRecap')}
                    />
                    {trans('task-reminders')}
                  </Typography>
                </Grid>
              </Grid>
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    </ModalDialog>
  );
}
