import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Box, Grid } from '@material-ui/core';
import { useTrans } from '../../../../../services/i18n';
import usePhoneInput from '../../../../../hooks/usePhoneInput';
import Typography from '../../../../common/Typography';
import { Button } from '../../../../common/Buttons';
import { CodeInput } from '../../../../common/Inputs';
import connect from '../connect';
import { ModalDialog } from '../../../../common/Dialogs';
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers';
import { useEffect } from 'react';

const PhoneInputWrapper = ({ authUser, updatePhone, updatePhoneVerified }) => {
  const { trans } = useTrans();
  const [open, onOpen, onClose] = useBooleanHandlers(false);

  const {
    phone,
    phoneVerified,
    sentCode,
    resendPending,
    code,
    pending,
    newPhone,
    resendCode,
    setCode,
    setPhone,
    handleSetPhone,
  } = usePhoneInput({ auth: authUser, onClose: () => {}, updatePhone, updatePhoneVerified });

  useEffect(() => {
    if (!sentCode) return;
    onOpen();
  }, [sentCode, onOpen]);

  const handleVerifyCode = () => {
    handleSetPhone().then(() => {
      onClose();
    });
  };

  if (authUser?.phoneVerified === true || authUser?.phoneVerified === 'true') return null;

  const getPhoneToShow = () => {
    if (authUser?.phoneVerified === 'true' || authUser?.phoneVerified === true) {
      return phone;
    }
    if ((authUser?.phoneVerified === 'false' || authUser?.phoneVerified === false) && sentCode === false) {
      return '';
    }
    if ((authUser?.phoneVerified === 'false' || authUser?.phoneVerified === false) && sentCode === true) {
      return newPhone;
    }
  };

  return (
    <>
      <Grid container style={{ marginBottom: '1rem', width: '100%' }}>
        <Grid item xs={12}>
          <Typography gutter="label" variant="body2" fontFamily="lexend" component="p" weight="500" color="label">
            {`${trans('enter-phone')} *`}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <PhoneInput
            defaultCountry="US"
            international
            placeholder="Enter phone number"
            value={getPhoneToShow()}
            onChange={setPhone}
            style={{
              background: '#f1f3f9',
              padding: '.25rem .5rem',
              borderRadius: '3px',
              fontFamily: 'Lexend',
              fontSize: '0.875rem',
              height: '3.375rem',
              marginRight: '1.3rem',
              width: '18.625rem',
            }}
          />

          <Button
            style={{ height: '3.25rem', width: '7.5rem', fontSize: '0.875rem', fontWeight: 700 }}
            variant="outlined"
            color="primary"
            onClick={handleSetPhone}
            pending={pending}
            disabled={
              ((!newPhone || newPhone === phone || pending) && (phoneVerified === true || phoneVerified === 'true')) ||
              sentCode
            }
          >
            {trans('verify')}
          </Button>
        </Grid>
      </Grid>

      <ModalDialog
        open={open}
        onClose={onClose}
        title={
          <Typography variant="h4" component="p" weight="bold">
            {trans('step-verification')}
          </Typography>
        }
        actions={
          <>
            <Button color="primary" pending={resendPending} onClick={resendCode}>
              {trans('resend-code')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyCode}
              pending={pending}
              disabled={!newPhone || code.length < 6 || pending}
            >
              {trans('verify')}
            </Button>
          </>
        }
      >
        {sentCode && (
          <Grid item xs={12}>
            <Typography variant="h6" component="p" color="label" gutter="bottom2">
              Please verify the CODE sent to your phone registered and enter below
            </Typography>

            <Typography gutter="label" variant="h6" component="p" weight="bold" color="label">
              {trans('enter-code')}
            </Typography>

            <CodeInput fields={6} type="number" onChange={(e) => setCode(e)} />
          </Grid>
        )}
      </ModalDialog>
    </>
  );
};

export default connect(PhoneInputWrapper);
