import { useState, useEffect, useCallback } from 'react';
import { Button } from '../../../../../components/common/Buttons';
import { ModalDialog } from '../../../../../components/common/Dialogs';
import { useTrans } from '../../../../../services/i18n';
import Grid from '@material-ui/core/Grid';
import { getInitialValues, useScheme } from './helpers';
import { useForm } from '../../../../../hooks/useForm';
import { TextField, Autocomplete } from '../../../../../components/common/Inputs';
import { application } from '../../../../../services/application';
import { useAlertContext } from '../../../../../contexts/AlertContext';
import { API } from '../../../../../constants';

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnSubmit: true,
};

const FormBillingInformation = ({ item, open, hideBackdrop = false, onClose, savePaymentMethod }) => {
  const { successAlert, errorAlert } = useAlertContext();
  const [isSend, setIsSend] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const schema = useScheme();
  const { trans } = useTrans();

  const onSubmit = useCallback(
    (values) => {
      if (typeof savePaymentMethod !== 'function') return;
      setIsSend(true);
      Promise.resolve(
        savePaymentMethod({
          ...values,
          country: values.country.id,
          state: values.state.id,
        })
      )
        .then((res) => {
          if (!res.success) {
            errorAlert(res.message);
          } else {
            successAlert(res.message);
            onClose();
          }
        })
        .finally(() => setIsSend(false));
    },
    [savePaymentMethod]
  );

  const { getFieldProps, handleSubmit, values } = useForm(schema, getInitialValues(item), onSubmit, formOptions);

  const getCountries = useCallback(async () => {
    const payload = await application.call(API.UTILITIES.GET_COUNTRIES, {});
    setCountries(payload.map(({ name, isoCode }) => ({ id: isoCode, name })));
  }, []);

  const getStates = async () => {
    const { id } = values.country;
    const payload = await application.call(API.UTILITIES.GET_STATES_BY_COUNTRY, { country: id });
    setStates(payload.map(({ name, isoCode }) => ({ id: isoCode, name })));
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (values.country.id) {
      getStates();
    }
  }, [values.country]);

  return (
    <ModalDialog
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={onClose}
      title={item ? trans('edit-billing') : trans('billing-data')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" pending={isSend} onClick={handleSubmit}>
              {!item ? trans('save-payment-method') : trans('save-billing')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <Autocomplete
            options={countries}
            required
            label={trans('country')}
            placeholder={trans('required')}
            disableClearable
            {...getFieldProps('country')}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            options={states}
            required
            label={trans('state')}
            placeholder={trans('required')}
            disableClearable
            {...getFieldProps('state')}
            disabled={!values.country}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            label={trans('city')}
            placeholder={trans('required')}
            required
            {...getFieldProps('city')}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item lg={9} md={9} xs={12}>
          <TextField
            label={trans('address')}
            placeholder={trans('required')}
            required
            {...getFieldProps('line1')}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={3} md={3} xs={12}>
          <TextField
            label={trans('zip-code')}
            placeholder={trans('required')}
            required
            {...getFieldProps('postal_code')}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

export default FormBillingInformation;
