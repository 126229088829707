import { createAction, createReducer, createAsyncThunk } from '@reduxjs/toolkit';
import { application } from '../../services/application';

const INITIAL_STATE = {
  onboardingViews: [],
  acceptQuoteRequests: true,
  acceptGrantQuoteRequests: false,
  coForQuoteRequests: [],
  emailExpired: '',
};

export const onboardingViews = createAction('globalSettings/onboardingViews');
function setOnboardingViews(state, action) {
  return Object.assign(state, { onboardingViews: action.payload });
}

export const markSkip = createAsyncThunk('globalSettings/markSkip', (params) => {
  return application.call('globalSettings.markSkipOnboarding', params);
});
function onMarkSkip(state, action) {
  const index = state.onboardingViews.findIndex(
    (i) => i.view === action.payload.view && i.section === action.payload.section
  );
  const newOnboardingViews = [...state.onboardingViews];
  newOnboardingViews[index].skip = true;

  return Object.assign(state, { onboardingViews: newOnboardingViews });
}

export const changeSkipState = createAction('globalSettings/changeSkipState');
function onChangeSkipState(state, action) {
  const index = state.onboardingViews.findIndex(
    (i) => i.view === action.payload.view && i.section === action.payload.section
  );
  const newOnboardingViews = [...state.onboardingViews];
  console.log(newOnboardingViews);
  newOnboardingViews[index].skip = action.payload.skip;

  return Object.assign(state, { onboardingViews: newOnboardingViews });
}

//get global settings
export const getGlobalSettings = createAsyncThunk('globalSettings/getGlobalSettings', (params) => {
  return application.call('globalSettings.getGlobalSettings', params);
});
const onGlobalSettings = (state, action) => {
  return Object.assign(state, {
    acceptQuoteRequests: action.payload.acceptQuoteRequests,
    acceptGrantQuoteRequests: action.payload.acceptGrantQuoteRequests,
    coForQuoteRequests: action.payload.coForQuoteRequests,
  });
};

export const setGlobalSettingsData = createAction('globalSettings/setGlobalSettingsData');

function onSetGlobalSettingsData(state, action) {
  return Object.assign(state, { ...action.payload });
}

export const setEmailExpiredAction = createAction('globalSettings/setEmailExpired');
export const cleanEmailExpiredAction = createAction('globalSettings/cleanEmailExpired');
const setEmailExpired = (state, action) => {
  const emailExpired = action.payload;
  return { ...state, emailExpired };
};
const cleanEmailExpired = (state, action) => {
  return { ...state, emailExpired: INITIAL_STATE.emailExpired };
};

export const authActions = {
  onboardingViews,
  markSkip,
  changeSkipState,
  getGlobalSettings,
  setGlobalSettingsData,
};

export const globalSettingsReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(onboardingViews, setOnboardingViews)
    .addCase(markSkip.fulfilled, onMarkSkip)
    .addCase(changeSkipState, onChangeSkipState)
    .addCase(getGlobalSettings.fulfilled, onGlobalSettings)
    .addCase(setGlobalSettingsData, onSetGlobalSettingsData)
    .addCase(setEmailExpiredAction, setEmailExpired)
    .addCase(cleanEmailExpiredAction, cleanEmailExpired);
});
