import React, { useEffect } from 'react';
import { CloseTitle, ModalDialog } from '../../../common/Dialogs/ModalDialog';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { Grid } from '@material-ui/core';
import Typography from '../../../common/Typography';
import { Button, RouterLink } from '../../../common/Buttons';
import { useTrans } from '../../../../services/i18n';
import hourglassImg from '../../../assests/hourglass.svg';
import { ROUTE_PLANS_PRIVATE } from '../../../../constants';

function ExpiredSubscriptionModal({ subscription, user }) {
  const [open, onOpen, onClose] = useBooleanHandlers(false);
  const { trans } = useTrans();

  useEffect(() => {
    if (subscription?.currentPlan === 'expired') {
      onOpen();
    }
  }, [subscription, onOpen]);

  return (
    <ModalDialog open={open} onClose={onClose} DialogTitle={<CloseTitle onClose={onClose} />}>
      <Grid container style={{ textAlign: 'center', marginTop: '1rem', padding: '1rem 1rem 2rem' }}>
        <Grid item xs={12}>
          <img src={hourglassImg} alt="hourglass" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" weight="800" component="span">
            Hi,{' '}
          </Typography>
          <Typography component="span" variant="h2" weight="800" color="secondary">
            {user?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <Typography>
            <span
              dangerouslySetInnerHTML={{
                __html: trans('expired-subscription-modal-message'),
              }}
            ></span>
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <Typography weight="800" variant="h4">
            {trans('lets-get-you-back-in')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{trans('to-continue-using-app-please-choose-a-plan')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            component={RouterLink}
            to={ROUTE_PLANS_PRIVATE.path}
          >
            {trans('select-plan')}
          </Button>
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default ExpiredSubscriptionModal;
