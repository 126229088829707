import React from 'react';
import * as Yup from 'yup';
import { Button } from '../../../components/common/Buttons';
import { useTrans } from '../../../services/i18n';
import AuthLayout from '../../../components/layouts/AuthLayout';
import {
  AUTH_NEW_PASSWORD_REQUIRED,
  AUTH_SMS_MFA,
  AUTH_SOFTWARE_TOKEN_MFA,
  ROUTE_MIGRATION,
  ROUTE_DASHBOARD,
  ROUTE_SIGNUP,
  ROUTE_PLANS_PUBLIC,
} from '../../../constants';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { AmplifyAuth, getAmplifyAuthData } from '../../../services/amplifyAuth';
import { useApplication } from '../../../services/application';
import SignInForm from './components/SignInForm';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import Authentication from './components/Authentication';
import useErrorState from '../../../hooks/useErrorState';
import ChangePasswordForm from '../ChangePassword/ChangePasswordForm';
import { shouldResendExceptions, temporaryPasswordExpired, userNotConfirmed } from './constants';

const useScheme = makeValidationSchema((trans) => {
  return Yup.object().shape({
    login: Yup.string().email(trans('enter-valid-email-password')).required(trans('enter-valid-email-password')),
    password: Yup.string().required(trans('enter-valid-email-password')),
  });
});

const SignIn = () => {
  const { trans } = useTrans();
  const history = useHistory();
  const application = useApplication();
  const schema = useScheme();

  const [error, onError, setError] = useErrorState();
  const [pending, setPending] = React.useState(false);
  const [user, setAuthUser] = React.useState(undefined);
  const [challenge, setChallenge] = React.useState(undefined);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const forgotPassword = queryParams.get('forgotPassword');
  const action = queryParams.get('action');

  const validateLearnWordsParams = async () => {
    let lwUrl = null;
    // Check learnWords conditions
    if (redirectUrl && action && action === 'login') {
      try {
        const resp = await application.call('learnWorlds.singleSignOn');
        lwUrl = resp.lw?.url;
      } catch (err) {
        console.log('🍉 validateLearnWordsParams error ', err);
        throw err;
      }
    }
    return lwUrl;
  };

  const loginToApplication = React.useCallback(
    async (user) => {
      const lwUrl = await validateLearnWordsParams();

      if (lwUrl) {
        window.location.href = lwUrl;
      } else {
        const { name } = await application.authorize(getAmplifyAuthData(user));
        if (!name) {
          history.push(ROUTE_MIGRATION.path);
        } else {
          history.push(redirectUrl || ROUTE_DASHBOARD.path);
        }
      }
    },
    [application, history, redirectUrl]
  );

  const signInUser = React.useCallback(
    async ({ login, password }) => {
      try {
        const user = await AmplifyAuth.signIn(login.toLowerCase(), password);

        if (user.challengeName === AUTH_NEW_PASSWORD_REQUIRED) {
          setAuthUser(user);
          return setChallenge(AUTH_NEW_PASSWORD_REQUIRED);
        }

        if (user.challengeName === AUTH_SMS_MFA || user.challengeName === AUTH_SOFTWARE_TOKEN_MFA) {
          setAuthUser(user);
          return setChallenge(user.challengeName);
        }

        return loginToApplication(user);
      } catch (e) {
        if (shouldResendExceptions.includes(e.code)) {
          return Promise.reject(userNotConfirmed);
        }
        if (
          e.code === 'NotAuthorizedException' &&
          e.message?.toLowerCase().includes('temporary password has expired and must be reset by an administrator')
        ) {
          return Promise.reject(temporaryPasswordExpired);
        }
        return Promise.reject(trans('incorrect-username-password'));
      }
    },
    [loginToApplication, trans]
  );

  const onSubmit = React.useCallback(
    (values) => {
      setPending(true);
      schema
        .validate(values)
        .then(signInUser)
        .catch((e) => {
          if (e === temporaryPasswordExpired) {
            setError(temporaryPasswordExpired);
            return;
          }
          if (e === userNotConfirmed) {
            setError(userNotConfirmed);
            return;
          }
          return onError(e);
        })
        .finally(() => setPending(false));
    },
    [onError, schema, signInUser]
  );

  const onMfaCancel = React.useCallback(
    (error) => {
      if (error) onError(error);
      setAuthUser(undefined);
      setChallenge(undefined);
      setPending(false);
    },
    [onError]
  );

  const onMfaSubmit = React.useCallback(
    (code) => {
      return setAuthUser((authUser) => {
        AmplifyAuth.confirmSignIn(authUser, code, challenge)
          .then(loginToApplication)
          .catch(() => onError(trans('incorrect-authentication-code'))) // Shout it be translated here?
          .finally(onMfaCancel);

        return undefined;
      });
    },
    [loginToApplication, onError, onMfaCancel, challenge]
  );

  const onNewPasswordSubmit = React.useCallback(
    ({ password }) => {
      return setAuthUser((authUser) => {
        AmplifyAuth.completeNewPassword(authUser, password)
          .then(loginToApplication)
          .then(() => {
            if (authUser.challengeParam.userAttributes.email_verified === 'false') {
              return application.call('organization.updateOrgUserStatus');
            }
          })
          .catch(() => onError(trans('incorrect-password'))) // Shout it be translated here?
          .finally(onMfaCancel);

        return undefined;
      });
    },
    [application, loginToApplication, onError, onMfaCancel, trans]
  );

  return (
    <AuthLayout
      actions={
        <Button
          id="sign-up-btn"
          color="secondary"
          variant="contained"
          component={RouterLink}
          to={ROUTE_PLANS_PUBLIC.path}
        >
          {trans('sign-up')}
        </Button>
      }
    >
      {[AUTH_SOFTWARE_TOKEN_MFA, AUTH_SMS_MFA].includes(challenge) ? (
        <Authentication challenge={challenge} onSubmit={onMfaSubmit} onCancel={onMfaCancel} user={user} />
      ) : challenge === AUTH_NEW_PASSWORD_REQUIRED ? (
        <ChangePasswordForm onSubmit={onNewPasswordSubmit} />
      ) : (
        <SignInForm
          onSubmit={onSubmit}
          pending={pending}
          openForgotPasswordModal={forgotPassword}
          error={error}
          onFlushError={() => {
            setError(undefined);
          }}
        />
      )}
    </AuthLayout>
  );
};

export default SignIn;
