import React, { useState, useEffect } from 'react';
import connect from '../connect';
import { useParams } from 'react-router-dom';
import { Paper } from '../../../components/common/Containers';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import Typography from '../../../components/common/Typography';
import { TextField, CheckBox, SelectField, DatePicker, Signature } from '../../../components/common/Inputs';
import RadioForm from '../../../components/common/Inputs/RadioForm';
import CheckboxForm from '../../../components/common/Inputs/CheckBoxForm';

import { Button } from '../../../components/common/Buttons';
import { Divider } from '../../../components/common/Divider';

import { Grid, MenuItem } from '@material-ui/core';
import { emptyArray } from '../../../utils/arrayUtils';
import dayjs from 'dayjs';
import { useTrans } from '../../../services/i18n';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { useAlertContext } from '../../../contexts/AlertContext';
import { UploadFilesField } from '../../../components/common/Inputs/File';
import SectionScrollable from '../../../components/common/Containers/SectionScrollable';

const SAM_BUSINESS_FORMSTACK_ID = '5124638';

const FormDetail = ({
  currentFormType,
  formDetail,
  getFormStackById,
  formSubmission,
  formPartialSubmission,
  uploadFilesForm,
  settings,
  getOrganizationInfo,
}) => {
  const params = useParams();
  const { trans } = useTrans();
  const history = useHistory();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const backForms = () => {
    history.push(`/client-portal-forms`);
  };

  const [fields, setFields] = useState([]);
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [partialSubmitting, setPartialSubmitting] = useState(false);
  const [busySection, setBusySection] = useState(false);
  const [files, setFiles] = useState([]);
  const [organization, setOrganization] = useState(null);

  const onSubmit = () => {
    setBusySection(true);
    if (hasSectionErrors()) {
      setBusySection(true);
      return;
    }
    setBusySection(false);
    setSubmitting(true);
    let formFields = {
      usfcr_form_id: formDetail.id,
      formType: currentFormType,
      formUsfcrId: formDetail.formUsfcrId,
    };

    const accountIdIndex = fields.findIndex((f) => f.name === 'accountid');
    const formParsedFields = [...fields];
    if (accountIdIndex !== -1) {
      formParsedFields[accountIdIndex].value = organization?.associatedSalesforceId;
    }

    formParsedFields.forEach((f) => {
      if (!f.visible_subfields) formFields[`field_${f.id}`] = f.value;
      if (f.visible_subfields) {
        const subfields = {};
        f.subfields.forEach((sf) => {
          subfields[sf.label] = sf.value;
        });
        formFields[`field_${f.id}`] = subfields;
      }
    });

    uploadFiles();

    formSubmission({ formFields, fields: formParsedFields }).then((res) => {
      successAlert('Form submitted successfully!');
      setSubmitting(false);
      backForms();
    });
  };

  const onPartial = (back = false, loading = false) => {
    if (loading) setPartialSubmitting(true);
    const accountIdIndex = fields.findIndex((f) => f.name === 'accountid');
    const formFields = [...fields];
    if (accountIdIndex !== -1) {
      formFields[accountIdIndex].value = organization?.associatedSalesforceId;
    }
    formPartialSubmission({
      formType: currentFormType,
      formUsfcrId: formDetail.formUsfcrId,
      formStackId: formDetail.id,
      currentSection: currentSection,
      fields: formFields,
    }).then((res) => {
      setPartialSubmitting(false);
      if (back) backForms();
    });
  };

  const saveAndResumeLater = () => {
    onPartial(true, true);
    //uploadFiles();
  };

  const changeSection = (action) => {
    if (action === 'next') {
      onPartial();
    }
    if (action === 'next' && hasSectionErrors()) {
      setBusySection(true);
      return;
    }

    setBusySection(false);

    let index = sections.findIndex((i) => i == currentSection);
    if (!currentSection) index = 0;
    if (action === 'next') {
      if (!currentSection) index = 0;
      setCurrentSection(sections[index + 1]);
      setCurrentSectionIndex(index + 1);
    } else {
      if (!currentSection) index = 1;
      setCurrentSection(sections[index - 1]);
      setCurrentSectionIndex(index - 1);
    }
  };

  const validateField = (field) => {
    let error = null;
    if (Boolean(parseInt(field.required))) {
      if (!field.value || field.value === 'none' || field.value === 'Please select a value') {
        error = 'Required';
        return error;
      }
    }

    if ((Boolean(parseInt(field.required)) || field.value) && Boolean(parseInt(field.minlength))) {
      if (field.value.length < field.minlength) {
        error = `Minimum of ${field.minlength} Characters`;
      }
    }

    if ((Boolean(parseInt(field.required)) || field.value) && Boolean(parseInt(field.maxlength))) {
      if (field.value.length > field.maxlength) {
        error = `Maximum of ${field.maxlength} characters`;
      }
    }

    if (field.type === 'email' && field.value) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      error = emailPattern.test(field.value) ? '' : 'The field must be a valid email';
    }

    if (field.type === 'email' && field.value) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      error = emailPattern.test(field.value) ? '' : 'The field must be a valid email';
    }

    if (field.type === 'phone' && field.value && field.phone_format === 'national') {
      const phonePattern = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
      error = phonePattern.test(field.value) ? '' : 'The field must be a valid phone - example (000) 000-0000';
    }

    // Validate fields with children
    if (!emptyArray(field.subfields)) {
      for (let index = 0; index < field.subfields.length; index++) {
        const subField = field.subfields[index];

        if (Boolean(parseInt(subField.required))) {
          if (!subField.value || subField.value === 'none' || subField.value === 'Please select a value') {
            error = 'Required';
            return error;
          }
        }

        if ((Boolean(parseInt(subField.required)) || subField.value) && Boolean(parseInt(subField.minlength))) {
          if (subField.value.length < subField.minlength) {
            error = `Minimum of ${subField.minlength} Characters`;
          }
        }

        if ((Boolean(parseInt(subField.required)) || subField.value) && Boolean(parseInt(subField.maxlength))) {
          if (subField.value.length > subField.maxlength) {
            error = `Maximum of ${subField.maxlength} characters`;
          }
        }

        if (subField.type === 'email' && subField.value) {
          const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          error = emailPattern.test(subField.value) ? '' : 'The field must be a valid email';
        }

        if (subField.type === 'email' && subField.value) {
          const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          error = emailPattern.test(subField.value) ? '' : 'The field must be a valid email';
        }

        if (subField.type === 'phone' && subField.value && subField.phone_format === 'national') {
          const phonePattern = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
          error = phonePattern.test(subField.value) ? '' : 'The field must be a valid phone - example (000) 000-0000';
        }
      }
    }

    return error;
  };

  const hasSectionErrors = () => {
    let error = false;
    const sectionFields = fields.filter((f) => f.section === currentSection);
    for (let f of sectionFields) {
      if (showField(f) && validateField(f)) {
        error = true;
        break;
      }
    }
    return error;
  };

  const changeField = (ev, newValue) => {
    const index = fields.findIndex((f) => f.id === ev.target.name);
    const copyFields = JSON.parse(JSON.stringify(fields));
    copyFields[index].value =
      ev.target.type === 'checkbox' && fields[index].options.length === 0
        ? ev.target.checked
        : copyFields[index].type === 'select'
        ? ev.target.value
        : newValue ?? ev.target.value;
    setFields(copyFields);
  };

  const changeSubField = (ev, field) => {
    const index = fields.findIndex((f) => f.id === field.id);
    const indexSubfield = field.subfields.findIndex((sf) => sf.id === ev.target.name);
    const copyFields = JSON.parse(JSON.stringify(fields));
    copyFields[index].subfields[indexSubfield].value = ev.target.value;
    setFields(copyFields);
  };

  const changeDatePickerField = (ev, name) => {
    const newValue = dayjs(ev).toISOString();
    const index = fields.findIndex((f) => f.id === name);
    fields[index].value = newValue;
    setFields(JSON.parse(JSON.stringify(fields)));
  };

  const showField = (field) => {
    // Operations
    const operations = {
      equals: function (a, b) {
        if (Array.isArray(a)) {
          return a.includes(b);
        } else {
          return a === b;
        }
      },
      different: (a, b) => a !== b,
      notequals: (a, b) => a !== b, // Added notequals operation
      '<': (a, b) => a < b,
      '>': (a, b) => a > b,
    };

    // If there's no logic, show the field by default
    if (!field.logic) return true;

    // Check conditions based on the logic
    for (let c of field.logic.checks) {
      const f = fields.find((fl) => String(fl.id) === String(c.field));
      const boolValue = operations[c.condition](f?.value, c.option);

      // If the conditional is 'all' and any condition is not met, hide the field
      if (field.logic.conditional === 'all' && !boolValue) {
        return field.logic.action === 'hide';
      }

      // If the conditional is 'any' and any condition is met
      if (field.logic.conditional === 'any' && boolValue) {
        return field.logic.action === 'show';
      }
    }

    // If none of the conditions for 'any' are met
    if (field.logic.conditional === 'any') return field.logic.action === 'hide';

    // If all conditions for 'all' are met
    return field.logic.action === 'show';
  };

  const changeFile = (ev) => {
    const filesCopy = [...files];
    const index = files.findIndex((f) => f.name === ev.target.name);
    filesCopy[index].value = ev.target.value
      ? { name: ev.target.value.originFile.name, value: ev.target.value.originFile }
      : null;
    setFiles(filesCopy);
  };

  const uploadFiles = () => {
    const formData = new FormData();

    formData.append('formType', currentFormType);
    formData.append('formUsfcrId', formDetail.formUsfcrId);
    formData.append('orgId', `${settings.id}`);
    formData.append('formStackId', formDetail.id);
    formData.append('formName', formDetail.name);
    files.forEach((f) => {
      formData.append(f.name, f.value.value);
    });

    uploadFilesForm(formData)
      .then((res) => {
        if (res && res.payload) {
          //successAlert('File uploaded successfully');
        }
      })
      .catch(() => {
        //errorAlert('An error has occurred');
      });
  };

  const getField = (field) => {
    if (field.type === 'text')
      return (
        <>
          <TextField
            label={field.label}
            placeholder={field.placeholder}
            name={field.id}
            value={field.value}
            onChange={changeField}
            readOnly={Boolean(parseInt(field.readonly))}
            error={busySection ? validateField(field) : null}
          />
        </>
      );
    if (field.type === 'phone')
      return (
        <TextField
          label={field.label}
          placeholder={field.placeholder}
          name={field.id}
          value={field.value}
          onChange={changeField}
          readOnly={Boolean(parseInt(field.readonly))}
          error={busySection ? validateField(field) : null}
        />
      );
    if (field.type === 'email')
      return (
        <TextField
          label={field.label}
          placeholder={field.placeholder}
          name={field.id}
          value={field.value}
          onChange={changeField}
          readOnly={Boolean(parseInt(field.readonly))}
          error={busySection ? validateField(field) : null}
        />
      );
    if (field.type === 'number')
      return (
        <TextField
          label={field.label}
          placeholder={field.placeholder}
          name={field.id}
          value={field.value}
          onChange={changeField}
          readOnly={Boolean(parseInt(field.readonly))}
          error={busySection ? validateField(field) : null}
        />
      );
    if (field.type === 'datetime')
      return (
        <DatePicker
          label={field.label}
          placeholder={field.placeholder}
          name={field.id}
          value={field.value}
          disableToolbar
          onChangeValue={(ev) => changeDatePickerField(ev, field.id)}
          error={busySection ? validateField(field) : null}
        />
      );
    if (field.type === 'textarea')
      return (
        <TextField
          label={field.label}
          placeholder={field.placeholder}
          multiline
          rows={6}
          name={field.id}
          value={field.value}
          onChange={changeField}
          readOnly={Boolean(parseInt(field.readonly))}
          error={busySection ? validateField(field) : null}
        />
      );
    if (field.type === 'radio')
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Typography
            style={{ width: '100%' }}
            weight="500"
            fontFamily="lexend"
            color="label"
            gutter="label"
            variant="body2"
          >
            {field.label}
          </Typography>
          <RadioForm
            options={field.options.map((i) => ({ name: i.label, value: i.value }))}
            name={field.id}
            value={field.value}
            onChange={changeField}
            error={busySection ? validateField(field) : null}
          />
        </div>
      );
    if (field.type === 'checkbox') {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Typography
            style={{ width: '100%' }}
            weight="500"
            fontFamily="lexend"
            color="label"
            gutter="label"
            variant="body2"
          >
            {field.label}
          </Typography>
          {field.options && field.options.length > 0 ? (
            <CheckboxForm
              options={field.options.map((i) => ({ name: i.label, value: i.value }))}
              name={field.id}
              value={field.value}
              onChange={changeField}
              error={busySection ? validateField(field) : null}
            />
          ) : (
            <CheckBox
              name={field.id}
              value={field.value}
              checked={field.value}
              onChange={changeField}
              error={busySection ? validateField(field) : null}
            />
          )}
          {/* or you can render another component or message here */}
        </div>
      );
    }

    if (field.type === 'select')
      return (
        <div>
          <Typography
            style={{ width: '100%' }}
            weight="500"
            fontFamily="lexend"
            color="label"
            gutter="label"
            variant="body2"
          >
            {field.label}
          </Typography>

          <SelectField
            type="select"
            placeholder={field.placeholder}
            name={field.id}
            value={field.value}
            onChange={changeField}
            error={busySection ? validateField(field) : null}
          >
            {field.options.map((item, ind) => (
              <MenuItem key={ind} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </SelectField>
        </div>
      );

    if (field.type === 'file')
      return (
        <div style={{ marginBottom: '16px' }} key={field.label}>
          <UploadFilesField
            placeholder="Select File"
            name={field.label.replaceAll(' ', '_')}
            label={field.label}
            value={getValueFile(field.label.replaceAll(' ', '_'))}
            onChange={changeFile}
            multiple={false}
          />
        </div>
      );
    if (field.type === 'signature')
      return (
        <div style={{ marginBottom: '16px' }} key={field.label}>
          <Signature name={field.id} label={field.label} value={field.value} onChange={changeField} />
        </div>
      );

    return field.type;
  };

  const getValueFile = (nombre) => {
    const index = files.findIndex((f) => f.name === nombre);
    return index !== -1 && files[index] ? files[index].value : '';
  };

  const getCustomValue = (form, field, organizationInfo, defaultValue) => {
    switch (form.name.trim()) {
      case 'SAM - Business':
        switch (field.name.trim()) {
          case 'uei':
            return organizationInfo?.details?.ueiCode;
          case 'cage':
            return organizationInfo?.details?.cageCode;
          case 'legal_business_name':
            return organizationInfo?.orgName;
          case 'accountid':
            return organizationInfo?.associatedSalesforceId;
          case 'where_is_your_company_located':
            return organizationInfo?.details?.country.includes('United States') ? 'United States' : 'International';
          case 'address':
            return organizationInfo?.details?.addressLine1;
          case 'address2':
            return organizationInfo?.details?.addressLine2;
          case 'city':
            return organizationInfo?.details?.city;
          case 'state':
            return organizationInfo?.details?.state;
          case 'zip':
            return organizationInfo?.details?.zipCode;
          case 'country':
            return organizationInfo?.details?.country;
          case 'corporate_structure':
            const corporateStructureMapping = {
              LLC: 'Limited Liability Company (LLC)',
              Corporation: 'Corporation',
              LP: 'Limited Partnership (LP)',
              'Sole Prop': 'Sole Proprietorship',
              'S-Corp': 'S-Corporation',
              International: 'International Organization',
              'Gov Entity': 'Government Entity',
            };
            return corporateStructureMapping[organizationInfo?.details?.corporateStructure] || '';
          case 'profit_structure':
            const profitStructureMapping = {
              profit: 'For Profit',
              nonprofit: 'Non-Profit',
            };
            return profitStructureMapping[organizationInfo?.details?.profitType] || '';
          case 'pick_the_majority_owners_ethnicity_minority_status':
            return organizationInfo?.details?.majorityEthnicity;
          case 'what_is_the_companys_primary_six_6_digit_naics_code':
            return organizationInfo?.details?.primaryNaics && organizationInfo?.details?.primaryNaics.length > 0
              ? organizationInfo?.details?.primaryNaics.join('\n')
              : '';
          case 'list_any_secondary_naics_codes_or_provide_a_detailed_description_of_what_your_company_provides':
            return organizationInfo?.details?.secondaryNaics && organizationInfo?.details?.secondaryNaics.length > 0
              ? organizationInfo?.details?.secondaryNaics.join('\n')
              : '';
          case 'socioeconomic_categories':
            const socioeconomicCategoriesMapping = {
              WOSB: ['Women-Owned Business', 'Women-Owned Small Business'],
              EDWOSB: ['Economically Disadvantaged Women-Owned Small Business'],
              VOSB: ['Veteran Owned Business'],
              SDVOSB: ['Service Disabled Veteran Owned Business'],
              MINORITY: ['Minority Owned Business'],
            };

            const categories = organizationInfo?.details?.socioEconomicCategories || [];
            const result = categories.flatMap((category) => socioeconomicCategoriesMapping[category] || []);
            return result;
          default:
            return '';
        }
      case 'SAM - FAR':
        switch (field.name.trim()) {
          case 'accountid':
            return organizationInfo?.associatedSalesforceId;
          default:
            return '';
        }
      default:
        return defaultValue;
    }
  };

  useEffect(() => {
    setLoading(true);
    getFormStackById({ id: params.id, formType: currentFormType }).then(async (res) => {
      // New form design, only for SAM - Business form for now
      if (res?.payload?.form?.id === SAM_BUSINESS_FORMSTACK_ID) {
        history.push(`/client-portal/forms-v2/${params.id}`);
        return;
      }

      // If it is not the SAM - Business
      let orgData = await getOrganizationInfo({ includeDetails: true });
      setOrganization(orgData.payload.orgInfo);
      setLoading(false);
      const sectionFields = [];
      const allSections = [];
      let activeSection = null;
      let formFiles = [];
      for (let f of res.payload.form.fields) {
        if (f.type === 'section' && activeSection) {
          activeSection = f.id;
        } else if (!activeSection) {
          activeSection = f.id;
        }
        //If Field is file type
        if (f.type === 'file' && f.label) {
          formFiles.push({
            fileName: f.label,
            name: f.label.replaceAll(' ', '_'),
            value: '',
            custom: false,
          });
        }

        const subfields = [];
        let partialValue = null;

        if (f.visible_subfields) {
          for (let index = 0; index < f.visible_subfields.length; index++) {
            const ivs = f.visible_subfields[index];
            //if partial submission

            if (res.payload.form.partialSubmission && !emptyArray(res.payload.form.partialSubmission)) {
              partialValue = res.payload.form.partialSubmission.find((i) => i.id == f.id);
              partialValue = partialValue.subfields.find((is) => is.label == ivs);
            }

            subfields.push({
              label: ivs,
              id: `${f.id}_${ivs}`,
              value: partialValue
                ? partialValue.value
                : getCustomValue(res.payload.form, { name: ivs }, organization, ''),
              required: f.required,
            });
          }
        }

        //if partial submission
        partialValue = null;
        if (res.payload.form.partialSubmission && !emptyArray(res.payload.form.partialSubmission)) {
          partialValue = res.payload.form.partialSubmission.find((i) => i.id == f.id);
        }
        sectionFields.push({
          ...f,
          value:
            !partialValue || f.name === 'accountid'
              ? getCustomValue(res.payload.form, f, organization, f.default)
              : partialValue.value,
          hidden: parseInt(f.hidden),
          section: activeSection,
          subfields: subfields,
          required: f.visible_subfields ? 0 : f.required,
        });

        if (!Boolean(parseInt(f.hidden))) {
          const sectionExists = allSections.find((s) => s == activeSection);
          if (!sectionExists) allSections.push(activeSection);
        }
      }
      //allSections.push('files');
      //setFiles(formFiles);
      if (res.payload.form.files && !emptyArray(res.payload.form.files)) {
        allSections.push('files');
        const formFilesForm = res.payload.form.files.map((f) => ({
          fileName: f,
          name: f.replaceAll(' ', '_'),
          value: '',
          custom: true,
        }));

        formFiles = [...formFiles, ...formFilesForm];
      }
      setFiles(formFiles);
      setFields(sectionFields);
      setSections(allSections);
      const formCurrentSection = res.payload.form.current_section ?? allSections[0];
      const index = allSections.findIndex((i) => i == formCurrentSection);

      setCurrentSectionIndex(index);
      setCurrentSection(formCurrentSection);
    });
  }, [params.id]);
  return (
    <>
      <Loader open={loading} />

      {formDetail && !loading && (
        <SectionScrollable
          TopFixed={
            <Paper style={{ borderRadius: 0 }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  padding: '20px 30px',
                  borderRadius: 0,
                }}
              >
                <div>
                  <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => backForms()} />
                </div>
                <div>
                  <Typography variant="body1" weight="600">
                    {formDetail.name}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1" color="primary" weight="500">
                    {trans('Step')} {currentSectionIndex + 1} / {sections.length}
                    {!partialSubmitting && (
                      <Button
                        style={{ marginLeft: '20px' }}
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={() => (currentSectionIndex >= sections.length ? onSubmit() : onPartial(false, true))}
                      >
                        {submitting ? 'Submitting ...' : `${trans('save')}`}
                      </Button>
                    )}
                    {partialSubmitting && (
                      <Button style={{ marginLeft: '20px' }} size="small" variant="contained" color="success">
                        Saving form ...
                      </Button>
                    )}
                  </Typography>
                </div>
              </div>
            </Paper>
          }
        >
          <SpacingContainerBase px={4} py={7}>
            {formDetail && !emptyArray(fields) && (
              <>
                <Paper>
                  <Grid container spacing={5} style={{ padding: '80px' }}>
                    <Typography
                      variant="h4"
                      weight="bold"
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '30px',
                        color: '#c2c4cb',
                      }}
                    >
                      {currentSectionIndex + 1} / {sections.length}
                    </Typography>
                    {currentSection != 'files' &&
                      fields.map((f) =>
                        (f.type === 'section' && f.id == currentSection) ||
                        (f.type === 'richtext' && currentSection == f.section) ? (
                          <Grid item xs={12} key={f.id}>
                            {' '}
                            <Typography variant="h5" weight="bold" style={{ textAlign: 'center' }}>
                              {f.section_heading}
                            </Typography>
                            <div
                              style={{ textAlign: 'left' }}
                              dangerouslySetInnerHTML={{ __html: f.section_text }}
                            ></div>
                          </Grid>
                        ) : (
                          <>
                            {!Boolean(parseInt(f.hidden)) && currentSection == f.section && showField(f) && (
                              <>
                                {f.visible_subfields ? (
                                  <>
                                    <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                      <Typography
                                        style={{ width: '100%', marginBottom: 0 }}
                                        weight="500"
                                        fontFamily="lexend"
                                        color="label"
                                        gutter="label"
                                        variant="body2"
                                      >
                                        {f.label}
                                      </Typography>
                                    </Grid>
                                    {f.subfields.map((sf) => {
                                      return (
                                        <Grid item xs={3} key={sf.label}>
                                          <TextField
                                            label={sf.label}
                                            name={sf.id}
                                            value={sf.value}
                                            onChange={(ev) => changeSubField(ev, f)}
                                            error={validateField(sf)}
                                          />
                                        </Grid>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <Grid item xs={6}>
                                    {getField(f)}
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )
                      )}
                    {currentSection === 'files' && !emptyArray(files) && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h2"
                          weight="bold"
                          style={{ width: '100%', textAlign: 'center', marginBottom: '30px' }}
                        >
                          Files Required
                        </Typography>

                        {files
                          .filter((i) => i.custom === true)
                          .map((f) => (
                            <div style={{ marginBottom: '16px' }} key={f.fileName}>
                              <UploadFilesField
                                placeholder="Select File"
                                name={f.name}
                                label={f.fileName}
                                value={f.value}
                                onChange={changeFile}
                                multiple={false}
                              />
                            </div>
                          ))}
                      </Grid>
                    )}
                  </Grid>
                  <Divider light />

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '30px' }}>
                    <Button
                      disabled={currentSectionIndex === 0}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => changeSection('prev')}
                    >
                      {trans('previous-step')}
                    </Button>

                    <Button
                      disabled={partialSubmitting}
                      variant="text"
                      size="large"
                      color="primary"
                      onClick={() => saveAndResumeLater()}
                    >
                      {partialSubmitting ? 'Saving form ...' : trans('save-and-resume-later')}
                    </Button>

                    {currentSectionIndex + 1 !== sections.length ? (
                      <Button variant="contained" size="large" color="primary" onClick={() => changeSection('next')}>
                        {trans('next-step')}
                      </Button>
                    ) : (
                      <Button
                        disabled={submitting}
                        variant="contained"
                        size="large"
                        color="success"
                        onClick={() => onSubmit()}
                      >
                        {submitting ? 'Submitting ...' : `${trans('complete')} & Submit`}
                      </Button>
                    )}
                  </div>

                  {/*<pre>{fields ? JSON.stringify(fields, null, 2) : 'no'}</pre>*/}
                  {/* <pre>{formDetail ? JSON.stringify(formDetail, null, 2) : 'no'}</pre>
                <div dangerouslySetInnerHTML={{ __html: formDetail.html }}></div>*/}
                </Paper>
                {formDetail && emptyArray(fields) && (
                  <>
                    <Paper>
                      {' '}
                      <Typography>Form no found</Typography>
                    </Paper>
                  </>
                )}
              </>
            )}
          </SpacingContainerBase>
        </SectionScrollable>
      )}
    </>
  );
};

export default connect(FormDetail);
