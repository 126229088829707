import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const getRedirect = (isAuthenticated, noAuth, redirect) => {
  if (!(isAuthenticated === !noAuth)) {
    return redirect;
  }
};

export const AuthRoute = (props) => {
  const { noAuth = false, authorized, redirect, children, component, permissionKey, ...rest } = props;
  const redirectUrl = getRedirect(authorized, noAuth, redirect);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        redirectUrl ? (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location },
            }}
          />
        ) : component ? (
          React.createElement(component)
        ) : (
          children
        )
      }
    />
  );
};
