import { connect } from 'react-redux';
import { actions } from '../../../../store/contractOpportunities';
import { subscriptionActions } from '../../../../store/subscription';
import { authActions } from '../../../../store/auth';

function mapStateToProps(state) {
  return {
    authUser: state.auth,
    subscription: state.subscription,
    acceptQuoteRequests: state.globalSettings.acceptQuoteRequests,
    acceptGrantQuoteRequests: state.globalSettings.acceptGrantQuoteRequests,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePhone: (payload) => dispatch(authActions.updatePhone(payload)),
    updatePhoneVerified: (payload) => dispatch(authActions.updatePhoneVerified(payload)),
    setOpenModalPremium: (payload) => dispatch(subscriptionActions.setOpenModalPremium(payload)),
    createQuoteRequest: (payload) => dispatch(actions.createQuoteRequest(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
