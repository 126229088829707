import * as Yup from 'yup';
import dayjs from 'dayjs'; // Import dayjs for date manipulation

export const IS_BEFORE = 'IS_BEFORE';
export const IS_AFTER = 'IS_AFTER';
export const IS_BETWEEN = 'IS_BETWEEN';
export const WITHIN_THE_LAST = 'WITHIN_THE_LAST';
export const WITHIN_THE_NEXT = 'WITHIN_THE_NEXT';

export const DATE_FILTER_OPTIONS = [IS_BEFORE, IS_AFTER, IS_BETWEEN, WITHIN_THE_LAST, WITHIN_THE_NEXT];

export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';

export const DATE_UNIT_LIST = [WEEK, MONTH, YEAR];

export const ANY_OF_THOSE = 'ANY_OF_THOSE';
export const NONE_OF_THOSE = 'NONE_OF_THOSE';
export const STARTS_WITH = 'STARTS_WITH';
export const ENDS_WITH = 'ENDS_WITH';
export const CONTAINS = 'CONTAINS';
export const DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN';
export const EXISTS = 'EXISTS';
export const DOES_NOT_EXISTS = 'DOES_NOT_EXISTS';
export const ONLY_TEXT = 'ONLY_TEXT';

export const IS_GREATER = 'IS_GREATER';
export const IS_LESS = 'IS_LESS';

export const REQUIRED_STRING_FILTER_OPTIONS = [ANY_OF_THOSE, NONE_OF_THOSE];
export const STRING_FILTER_OPTIONS = [ANY_OF_THOSE, NONE_OF_THOSE, STARTS_WITH, ENDS_WITH, CONTAINS];
export const PARTIAL_FILTER_OPTIONS = [STARTS_WITH, ENDS_WITH, CONTAINS, DOES_NOT_CONTAIN];

export const NUMBER_FILTER_OPTIONS = [IS_GREATER, IS_LESS, IS_BETWEEN];

export const DEFAULT_DATE_STATE = { type: IS_BEFORE, dateUnit: MONTH };
export const DEFAULT_DATE_FROM_TODAY_STATE = { type: IS_AFTER, dateUnit: MONTH };
export const DEFAULT_STRING_STATE_SINGLE = { data: '', type: ONLY_TEXT };
export const DEFAULT_STRING_STATE = { data: [], type: ANY_OF_THOSE };
export const DEFAULT_NUMBER_STATE = { data: '', type: IS_GREATER, from: '', to: '' };
export const DEFAULT_PARTIAL_STATE = { text: '', type: STARTS_WITH };

export const DATE_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    from: Yup.date().when('type', {
      is: (val) => val === IS_BETWEEN || val === IS_AFTER,
      then: Yup.date().required(trans('required-field')),
      otherwise: Yup.date().optional(),
    }),
    to: Yup.date().when('type', {
      is: (val) => val === IS_BETWEEN || val === IS_BEFORE,
      then: Yup.date().required(trans('required-field')),
      otherwise: Yup.date().optional(),
    }),
    unitCount: Yup.number().when('type', {
      is: (val) => val === WITHIN_THE_LAST || val === WITHIN_THE_NEXT,
      then: Yup.number().typeError(trans('must-be-a-number')).required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    dateUnit: Yup.string().when('type', {
      is: (val) => val === WITHIN_THE_LAST || val === WITHIN_THE_NEXT,
      then: Yup.string().required(trans('required-field')),
      otherwise: Yup.string().optional(),
    }),
  });

export const STRING_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: (val) => val === EXISTS || val === DOES_NOT_EXISTS,
      then: Yup.array().min(0).optional(),
      otherwise: Yup.array().min(1).required(trans('required-field')),
    }),
    text: Yup.mixed().when('type', {
      is: (val) => [STARTS_WITH, ENDS_WITH, CONTAINS].includes(val),
      then: Yup.string().required(trans('required-field')),
    }),
  });

export const SELECTION_OR_STRING_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: (val) => val === ANY_OF_THOSE || val === NONE_OF_THOSE,
      then: Yup.array().min(1).required(trans('required-field')),
      otherwise: Yup.array().nullable(),
    }),
    text: Yup.mixed().when('type', {
      is: (val) => [STARTS_WITH, ENDS_WITH, CONTAINS].includes(val),
      then: Yup.string().required(trans('required-field')),
    }),
  });

export const SINGLE_VALUE_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().required(trans('required-field')),
  });

export const STRING_VALUE_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    text: Yup.string().required(trans('required-field')),
  });

export const NUMBER_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: (val) => val === IS_GREATER || val === IS_LESS,
      then: Yup.number()
        .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    from: Yup.number().when('type', {
      is: (val) => val === IS_BETWEEN,
      then: Yup.number()
        // .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    to: Yup.number().when('type', {
      is: (val) => val === IS_BETWEEN,
      then: Yup.number()
        // .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
  });

/***
 * Schema to avoid pick a date before today
 */
export const DATE_FROM_TODAY_SCHEMA = (trans) =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    from: Yup.date()
      .when('type', {
        is: (val) => val === IS_BETWEEN || val === IS_AFTER,
        then: Yup.date()
          .required(trans('required-field'))
          .test(
            'notBeforeToday', // Custom validation name
            trans('past-dates-are-not-permitted'), // Optional: Error message for past dates (can be removed)
            (value) => !value || dayjs(value).endOf('day').isAfter(dayjs().startOf('day')) // Validation with dayjs
          ),
      })
      .nullable(), // Allow null values for flexibility
    to: Yup.date()
      .when('type', {
        is: (val) => val === IS_BETWEEN || val === IS_BEFORE,
        then: Yup.date()
          .required(trans('required-field'))
          .test(
            'notBeforeToday', // Use the same validation name
            trans('past-dates-are-not-permitted'), // Optional: Error message for past dates (can be removed)
            (value) => !value || dayjs(value).endOf('day').isAfter(dayjs().startOf('day')) // Same validation logic
          ),
      })
      .nullable(), // Allow null values for flexibility
    unitCount: Yup.number().when('type', {
      is: (val) => val === WITHIN_THE_NEXT,
      then: Yup.number().typeError(trans('must-be-a-number')).required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    dateUnit: Yup.string().when('type', {
      is: (val) => val === WITHIN_THE_NEXT,
      then: Yup.string().required(trans('required-field')),
      otherwise: Yup.string().optional(),
    }),
  });
