import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTrans } from '../../../../services/i18n';
import Typography from '../../Typography';

export default function CheckboxForm({
  options,
  onChange,
  name = 'checkbox',
  value = [],
  disabled,
  error,
  row = false,
}) {
  const { trans } = useTrans();

  const handleCheckboxChange = (event) => {
    if (disabled) return;

    const newValue = [...value];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      const index = newValue.indexOf(event.target.value);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }
    onChange(event, newValue);
  };

  return (
    <FormControl component="fieldset" disabled={disabled}>
      {row ? (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderCheckboxes(options, name, value, handleCheckboxChange, trans)}
        </div>
      ) : (
        renderCheckboxes(options, name, value, handleCheckboxChange, trans)
      )}
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  );
}

function renderCheckboxes(options, name, value, handleCheckboxChange, trans) {
  return options.map((option, ind) => (
    <FormControlLabel
      key={ind}
      name={name}
      control={
        <Checkbox
          color="secondary"
          checked={value.includes(option.value)}
          onChange={handleCheckboxChange}
          value={option.value}
          id={`checkbox-${name}-${option.value}`}
        />
      }
      label={trans(option.name)}
    />
  ));
}
