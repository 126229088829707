import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from '../utils/debounce';

const filter = createFilterOptions();

export function useApiAutocomplete({
  labelKey = 'name',
  onChange,
  getOptionList: apiGetOptionList,
  createOption: apiCreateOption,
  createPrefix,
  getFilterByValue,
  apiParams = {},
  makeFilter,
  name,
}) {
  const [apiOptions, setApiOptions] = useState({});

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultMakeFilter = useCallback((id, value, others) => {
    const filter = {};
    if (id) filter.id = id;
    if (value) filter.text = value;
    if (others) filter.others = others;
    return filter;
  }, []);

  const getOptionLabel = useCallback(
    (option) => {
      if (!option) {
        return '';
      }
      if (typeof option === 'string') return option;
      if (option.create) return option.create;
      return option[labelKey];
    },
    [labelKey]
  );

  const filterOptions = useCallback(
    (options, state) => {
      const filtered = filter(options, {
        ...state,
        inputValue: state.inputValue.endsWith('*') ? state.inputValue.slice(0, -1) : state.inputValue,
      });
      if (state.inputValue !== '' && !filtered.length && typeof apiCreateOption === 'function') {
        filtered.push({
          create: state.inputValue,
          [labelKey]: `${state.inputValue}`,
          // [labelKey]: `${createPrefix || trans('create')} "${state.inputValue}"`,
        });
      }
      return filtered;
    },
    [labelKey, apiCreateOption]
  );

  const renderOption = useCallback((option) => option[labelKey], [labelKey]);

  const getOptionList = useMemo(() => {
    return debounce(async ({ id, value = '', limit = 50, others = false }) => {
      setLoading(true);
      try {
        let filterObj = {};
        if (typeof getFilterByValue === 'function') {
          filterObj = { ...getFilterByValue(value) };
        } else {
          filterObj = {
            filter: typeof makeFilter === 'function' ? makeFilter(id, value) : defaultMakeFilter(id, value, others),
          };
        }
        const result = await apiGetOptionList({
          ...filterObj,
          limit,
          order: [labelKey],
          ...apiOptions,
        });
        let items = [];
        if (result && result.payload) {
          items = result.payload.map((i) => ({ create: i.tagName, value: i.tagName }));
        } else if (Array.isArray(result)) {
          items = [...result];
        }
        setOptions(items);
      } catch (e) {
        console.warn(e.message);
      }
      setLoading(false);
    }, 300);
  }, [apiGetOptionList, makeFilter, defaultMakeFilter, getFilterByValue, labelKey, apiOptions]);

  const handleInputChange = useCallback(
    (e, value) => {
      if (e) e.persist();
      getOptionList({ value });
    },
    [getOptionList]
  );

  const createOption = useCallback(
    async (value) => {
      setLoading(true);
      try {
        const result = await apiCreateOption({ [labelKey]: value });
        setLoading(false);
        return result;
      } catch (e) {
        console.warn(e.message);
        return undefined;
      }
    },
    [labelKey, apiCreateOption]
  );

  const handleValueChange = useCallback(
    (e) => {
      return onChange && onChange(e, name);
    },
    [onChange, name]
  );

  const setApiParams = useCallback(
    (newParams) => {
      setApiOptions({ ...apiOptions, newParams });
      getOptionList({});
    },
    [apiOptions, getOptionList]
  );

  useEffect(() => {
    setApiOptions(apiParams);
    getOptionList({});
    return () => {
      getOptionList.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    options,
    loading,
    setApiParams,
    getOptionLabel,
    filterOptions,
    renderOption,
    handleValueChange,
    handleInputChange,
    getOptionList,
    createOption,
  };
}
