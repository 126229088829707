import { Box, Paper, useTheme } from '@material-ui/core';
import React from 'react';
import { AlertButton, CenterBox } from '../styled';
import Typography from '../../../components/common/Typography';
import { Divider } from '../../../components/common/Divider';
import { Button } from '../../../components/common/Buttons';
import { useTrans } from '../../../services/i18n';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { alpha as fade } from '@material-ui/core/styles/colorManipulator';

function PlanCard({
  name = 'Not Set',
  price = '',
  isSubscribed = false,
  onSubscribeClick,
  priceColor = 'success',
  buttonColor = 'primary',
  buttonText,
  canSubscribe = true,
  priceIntervalText = '',
  priceDescription = '',
  redirectUrl = undefined,
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { trans } = useTrans();

  const mobileStyle = isMobile ? { width: '100%', marginBottom: '2rem' } : { maxWidth: '30vw', minWidth: '20vw' };

  const buttonStyle = {
    padding: '1.5rem 1.75rem',
    borderRadius: '6.25rem',
    fontSize: '1rem',
    lineHeight: '1.28rem',
    textTransform: 'uppercase',
  };

  return (
    <Paper style={{ display: 'flex', flexDirection: 'column', height: '100%', ...mobileStyle }}>
      <Paper
        style={{ backgroundColor: '#f1f3f9', padding: '2rem 0', borderRadius: '1rem 1rem 5rem 5rem' }}
        elevation={0}
      >
        <CenterBox style={{ padding: '.75rem 0' }}>
          <Box>
            <Typography variant={'h1'} weight="600" color={priceColor} component="span">
              {name}
            </Typography>
          </Box>
        </CenterBox>
      </Paper>

      <CenterBox style={{ padding: isMobile ? '2rem 0' : '4rem 0' }}>
        <Typography variant={'h1'} weight="800" color={priceColor} style={{ marginBottom: '.5rem' }}>
          {trans(price)}
          <Typography variant={'h4'} weight="500" color="hint" component="span">
            {' ' + priceIntervalText}
          </Typography>
        </Typography>

        <Typography
          variant={isMobile ? 'h5' : 'h6'}
          weight="700"
          color={buttonColor}
          style={{
            backgroundColor: fade(theme.palette[buttonColor].main, 0.06),
            padding: '.5rem .8rem',
            borderRadius: '3.75rem',
          }}
        >
          {priceDescription}
        </Typography>

        {isMobile && (
          <Box style={{ marginTop: '2rem' }}>
            {isSubscribed ? (
              <AlertButton variant="outlined" color={buttonColor} theme={theme} style={buttonStyle}>
                {trans('current-plan')}
              </AlertButton>
            ) : (
              <Button
                variant="contained"
                color={buttonColor}
                onClick={onSubscribeClick}
                size="large"
                style={buttonStyle}
              >
                {buttonText ? buttonText : trans('subscribe')}
              </Button>
            )}
          </Box>
        )}
      </CenterBox>

      {canSubscribe && !isMobile && (
        <Box>
          <Divider light />
          <CenterBox style={{ padding: '1.5rem 0 1rem' }}>
            {isSubscribed ? (
              <AlertButton variant="outlined" color={buttonColor} theme={theme} style={buttonStyle}>
                {trans('current-plan')}
              </AlertButton>
            ) : (
              <Button variant="contained" color={buttonColor} onClick={onSubscribeClick} style={buttonStyle}>
                {buttonText ? buttonText : trans('subscribe')}
              </Button>
            )}
          </CenterBox>
        </Box>
      )}
    </Paper>
  );
}

export default PlanCard;
