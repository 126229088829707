import React from 'react';
import { useTrans } from '../../../services/i18n';
import RouterLink from '../../common/Buttons/RouterLink';
import Typography from '../../common/Typography';

export default function FieldValue({
  value,
  children,
  label,
  to,
  href,
  noValueText = '',
  noWrap = true,
  color = '',
  variant = 'h6',
  colorLabel = 'hint',
  weight = '500',
  weightLabel = '500',
  fontFamily = 'lexend',
  icon = '',
  variantLabel = 'body2',
}) {
  const { trans } = useTrans();

  return (
    <>
      <div style={{ display: 'flex', gap: 5 }}>
        {icon}
        <div style={{ wordBreak: 'break-word' }}>
          <Typography fontFamily={fontFamily} weight={weightLabel} variant={variantLabel} color={colorLabel}>
            {label}
          </Typography>
          {!!value ? (
            <Typography
              variant={variant}
              component={to || href ? RouterLink : 'p'}
              to={to || undefined}
              href={href || undefined}
              color={color}
              weight={weight}
              fontFamily={fontFamily}
            >
              {value}
            </Typography>
          ) : children ? (
            children
          ) : (
            <Typography variant="h6" component="p" color="hint" noWrap={noWrap} fontFamily={fontFamily}>
              {noValueText || trans('not-provided')}
            </Typography>
          )}
        </div>
      </div>
    </>
  );
}
