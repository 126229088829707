import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_PRETTY_FORMAT, ISO_STANDART_DATE_TIME_FORMAT } from '../constants';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

const DATE_SHORT_OPTIONS = { month: 'short', day: 'numeric', year: 'numeric' };
const DATETIME_SHORT_OPTIONS = { ...DATE_SHORT_OPTIONS, hour: 'numeric', minute: 'numeric' };

/* INTL */
export function intlDateFormat(date, locale = 'en-US', options = DATE_SHORT_OPTIONS) {
  try {
    if (!date) return 'Not provided';

    const op = options;
    const authTimezone = localStorage.getItem('authTimezone');

    if (authTimezone && authTimezone !== 'undefined') {
      op.timeZone = authTimezone;
    }

    return new Date(date).toLocaleDateString(locale, options);
  } catch (e) {
    return '';
  }
}

export const intlDateWithTimeFormat = (date) => intlDateFormat(date, 'en-US', DATETIME_SHORT_OPTIONS);

/* FORMAT */
export const formatDate = (date, customFormat = DATE_FORMAT, dateFormat) => {
  try {
    if (!date) return '';
    const authFormatDate = localStorage.getItem('authFormatDate');
    const authTimezone = localStorage.getItem('authTimezone');
    const dateF = dayjs(date, dateFormat);

    if (authTimezone && authTimezone !== 'undefined') {
      dateF.tz(authTimezone);
    }
    return dateF.format(authFormatDate && authFormatDate !== 'undefined' ? authFormatDate : customFormat);
  } catch (err) {
    return '';
  }
};

export const formatDatePretty = (date, customFormat = DATE_PRETTY_FORMAT) => {
  if (!date) return '';
  return dayjs(date).format(customFormat);
};

export const formatPrettyDate = (date, customFormat = DATE_PRETTY_FORMAT) => {
  return formatDate(date, customFormat);
};
export const formatPrettyUnixDate = (date, customFormat = DATE_PRETTY_FORMAT) => dayjs.unix(date).format(customFormat);

export const formatISODate = (date, customFormat = DATE_FORMAT) =>
  formatDate(date, customFormat, ISO_STANDART_DATE_TIME_FORMAT);

export const excludeIfEqualBadDates = (date, utcOffset = undefined) => {
  let parsedDate = dayjs(date);

  if (utcOffset !== undefined) {
    parsedDate = parsedDate.utcOffset(utcOffset);
  }

  if (!parsedDate.isValid()) {
    return null;
  }

  const dateF = parsedDate.format('YYYY-MM-DD');
  const dateMD = parsedDate.format('MM-DD');
  const dateY = parsedDate.format('YYYY');
  const now = dayjs().format('YYYY');

  const badDates = [dayjs('1969-12-31').format('YYYY-MM-DD'), dayjs('1970-01-01').format('YYYY-MM-DD')];
  if (badDates.includes(dateF)) return null;

  if (dateY < now - 1000) {
    return formatPrettyDate(`2${dateY.toString().substring(1)}-${dateMD}`, DATE_FORMAT);
  }

  return formatPrettyDate(parsedDate, DATE_FORMAT);
};

/***
 * New method to format correctly
 */
export const excludeIfEqualBadDatesFormat = (date, utcOffset = undefined, customFormat = DATE_FORMAT) => {
  let parsedDate = dayjs(date);

  if (utcOffset !== undefined) {
    parsedDate = parsedDate.utcOffset(utcOffset);
  }

  if (!parsedDate.isValid()) {
    return null;
  }

  const dateF = parsedDate.format('YYYY-MM-DD');
  const dateMD = parsedDate.format('MM-DD');
  const dateY = parsedDate.format('YYYY');
  const now = dayjs().format('YYYY');

  const badDates = [dayjs('1969-12-31').format('YYYY-MM-DD'), dayjs('1970-01-01').format('YYYY-MM-DD')];
  if (badDates.includes(dateF)) return null;

  if (dateY < now - 1000) {
    return formatDatePretty(`2${dateY.toString().substring(1)}-${dateMD}`, customFormat);
  }

  return formatDatePretty(parsedDate, customFormat);
};

/* COMPARISON */
export const isTodayOrAfter = (date) => {
  if (!date) return false;
  return dayjs().isSameOrAfter(dayjs(date), 'day');
};

/***
 * Format and end date
 */
export function intlDateWithTimeFormatEndDate(date) {
  try {
    if (!date) return 'Not provided';
    const newDate = dayjs(date).endOf('day');
    return newDate.format('MMM D, YYYY, hh:mm A');
  } catch (e) {
    return '';
  }
}

/***
 * Given a date, add to it x days
 */
export const addDays = (date, days) => {
  const dateFormatted = dayjs(date);
  // Adding days to the date
  return dateFormatted.add(days, 'day'); // Adding x days to the date
};

/***
 * Given a range of dates, format them in a pretty way
 * Ex: August 1 - 3, 2021
 */
export const formatDateRangePretty = (startDate, endDate) => {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);

  // Format the start and end dates
  const formattedStartDate = startDate.format('MMMM D');
  const formattedEndDate = endDate.format('D');

  // Check if the dates span across different months
  const isSameMonth = startDate.isSame(endDate, 'month');
  console.log('🚀 ~ formatDateRangePretty ~ isSameMonth:', isSameMonth);

  // Format the year
  const year = startDate.format('YYYY');

  // Format the month for the end date if different
  const formattedEndMonth = isSameMonth ? '' : endDate.format('MMMM ');

  // Construct the final date range string
  const formattedDateRange = `${formattedStartDate} - ${formattedEndMonth}${formattedEndDate}, ${year}`;

  return formattedDateRange;
};

/**
 * Function to calculate the difference in days between a given UTC date and the current date in the user's timezone
 * @param {string} inputDate - The input date in UTC format (e.g., '2023-05-01T00:00:00Z')
 * @returns {number} - The number of days between the input date and the current date in the user's timezone
 */
export const calculateDaysDifferenceUTC = (inputDate) => {
  try {
    // Get user timezone from localStorage
    const authTimezone = localStorage.getItem('authTimezone') || 'America/Los_Angeles';

    // Parse the input date as a UTC date
    const givenDate = dayjs(inputDate).utc();

    // Convert givenDate to the user's timezone for accurate comparison
    const givenDateInUserTz = givenDate.tz(authTimezone).startOf('day'); // Ensure both dates are at the start of the day

    // Get the current date in the user's timezone
    const currentDate = dayjs().tz(authTimezone).startOf('day'); // Ensure both dates are at the start of the day

    // Calculate the difference in days between the given date and the current date in the user's timezone
    const differenceInDays = givenDateInUserTz.diff(currentDate, 'day');

    // Return the difference in days
    return differenceInDays;
  } catch (error) {
    console.error('Error calculating difference in days:', error);
    return null;
  }
};

/**
 * Function to transform a given UTC date to the user's timezone and format it
 * @param {string} inputDate - The input date in UTC format (e.g., '2023-05-01T00:00:00Z')
 * @param {string} dateFormat - The format of the input date (default is 'YYYY-MM-DD')
 * @returns {string} - The transformed date in the user's timezone, formatted as specified
 */
export const transformDateToUserTz = (inputDate, dateFormat = 'MM/DD/YYYY') => {
  try {
    // Get user timezone from localStorage
    const authTimezone = localStorage.getItem('authTimezone') || 'America/New_York'; // Use 'America/New_York' as the most common timezone in the US
    // Parse the input date as a UTC date
    const givenDate = dayjs(inputDate).utc();
    // Convert givenDate to the user's timezone and format it
    const givenDateInUserTz = givenDate.tz(authTimezone).format(dateFormat);
    return givenDateInUserTz;
  } catch (error) {
    console.error('Error transforming date:', error);
    return null;
  }
};

/**
 * Function to transform a given UTC date to the user's timezone, format it, and check if it is before today
 * @param {string} inputDate - The input date in UTC format (e.g., '2023-05-01T00:00:00Z')
 * @param {string} dateFormat - The format of the output date (default is 'MM/DD/YYYY')
 * @returns {Object} - An object containing the transformed date and a boolean indicating if the date is before today
 */
export const isDateBeforeNow = (inputDate, dateFormat = 'MM/DD/YYYY') => {
  try {
    // Get user timezone from localStorage
    const authTimezone = localStorage.getItem('authTimezone') || 'America/New_York'; // Use 'America/New_York' as the fallback timezone

    // Parse the input date as a UTC date
    const givenDate = dayjs(inputDate).utc();

    // Convert the given date to the user's timezone and format it
    const givenDateInUserTz = givenDate.tz(authTimezone).format(dateFormat);

    // Get the current date in the user's timezone, without the time part
    const currentDateInUserTz = dayjs().tz(authTimezone).startOf('day');

    // Check if the given date is before today
    const isBeforeToday = givenDate.tz(authTimezone).isBefore(currentDateInUserTz, 'day');

    // Return an object with the formatted date and the check result
    return {
      formattedDate: givenDateInUserTz,
      isBeforeToday: isBeforeToday,
    };
  } catch (error) {
    console.error('Error transforming date:', error);
    return null;
  }
};

/**
 * Calculates the time remaining until a proposal deadline and returns a message indicating.
 *
 * @param {string} proposalDeadline - The deadline date in UTC format (e.g., '2023-05-01T00:00:00Z').
 * @returns {object} - An object containing:
 *   - `result`: A string indicating how much time is left until the deadline, formatted appropriately based on days, hours, or minutes.
 *   - `difference`: The number of days remaining until the deadline.
 *   - `isExpired`: A boolean flag indicating if the deadline has already passed.
 */
export const getDueIn = (proposalDeadline) => {
  // Initialize the result as 'Expired' and days as null
  let result = 'Expired';
  let days = null;
  let isExpired = false;

  // Check if the proposal deadline is provided
  if (proposalDeadline) {
    // Parse the input date as a UTC date
    const givenDate = dayjs(proposalDeadline).utc();
    // Get the current date in UTC
    const currentDate = dayjs().utc();
    // Calculate the difference in days between the given date and the current date
    const differenceInDays = givenDate.diff(currentDate, 'day');

    // Calculate the difference in milliseconds between the proposal deadline and the current date
    const difference = dayjs(proposalDeadline).diff(dayjs());
    // Create a duration object from the difference
    const duration = dayjs.duration(difference);
    // Extract the number of days from the duration
    days = differenceInDays;
    // Extract the number of hours from the duration
    const hours = duration.hours();
    // Extract the number of minutes from the duration
    const minutes = duration.minutes();
    // If the proposal deadline is in the past, keep the result as 'Expired'
    if (minutes <= 0) {
      result = 'Expired';
      isExpired = true;
    } else if (days === 0) {
      // If the deadline is today, set the result in hours
      if (hours > 1) {
        result = `Due in ${hours} hours`;
      } else if (hours === 1) {
        result = `Due in ${hours} hour`;
      } else {
        result = `Due in ${minutes} minute(s)`;
      }
    } else if (days === 1) {
      // If the deadline is tomorrow, set the result to 'Due in 1 day'
      result = 'Due in 1 day';
    } else if (days > 1) {
      // If the deadline is in more than one day, set the result to 'Due in X days'
      result = `Due in ${days} days`;
    }
  } else {
    result = 'Expired';
    days = 0;
    isExpired = true;
  }

  // Return the result and the number of days
  return { result, difference: days, isExpired };
};
