import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_VENDORS } from '../../constants';
import { application } from '../../services/application';
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases';
import { INITIAL_AWARDS_COUNT_STATE, INITIAL_FUNDING_STATE } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithReportsActions,
  generateListActions,
  generateReportsActions,
  INITIAL_LIST_STATE,
  INITIAL_LIST_STATE_EXTENDED,
} from '../helpers/listActionsHelpers';

const INITIAL_STATE = {
  item: {},
  teaming: null,
  asideList: [],
  pending: false,
  teamingPending: false,
  memberPending: false,
  notePending: false,
  naicsPending: false,
  funding: INITIAL_FUNDING_STATE,
  awards: INITIAL_AWARDS_COUNT_STATE,
  federalContractAwards: INITIAL_LIST_STATE,
  fundingAnalysis: INITIAL_LIST_STATE,
  orgAvatarUrl: null,
  notes: { ...INITIAL_LIST_STATE_EXTENDED, pagination: { page: 1, perPage: 2 } },
  members: INITIAL_LIST_STATE_EXTENDED,
  naics: INITIAL_LIST_STATE,
};

const addVendorMember = createAsyncThunk('vendors/addVendorMember', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE_MEMBER, params);
});
const removeVendorMember = createAsyncThunk('vendors/removeVendorMember', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE_MEMBER, params);
});
const updateVendorMember = createAsyncThunk('vendors/updateVendorMember', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE_MEMBER, params);
});

const addVendorNote = createAsyncThunk('vendors/addVendorNote', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE, params);
});
const removeVendorNote = createAsyncThunk('vendors/removeVendorNote', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE, params);
});
const updateVendorNote = createAsyncThunk('vendors/updateVendorNote', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE, params);
});
const addVendorNaics = createAsyncThunk('vendors/addVendorNaics', async (params) => {
  return application.call(API.VENDORS.ADD_VENDOR_NOTE_NAICS, params);
});
const removeVendorNaics = createAsyncThunk('vendors/removeVendorNaics', async (params) => {
  return application.call(API.VENDORS.REMOVE_VENDOR_NOTE_NAICS, params);
});
const updateVendorNaics = createAsyncThunk('vendors/updateVendorNaics', (params) => {
  return application.call(API.VENDORS.UPDATE_VENDOR_NOTE_NAICS, params);
});

const fetchVendor = createAsyncThunk('vendors/findOne', (params) => {
  return application.call(API.VENDORS.FIND_ONE, params);
});

const addToFavorite = createAsyncThunk('vendors/toggleFavorite', (params) => {
  return application.call(API.VENDORS.TOGGLE_FAVORITE, params);
});

const addVendorOption = createAsyncThunk('vendors/vendorOptions', (params) => {
  return application.call(API.VENDORS.TOGGLE_VENDOR_OPTION, params);
});

const onUpdateNote = createAsyncThunk('vendors/vendorNotes', (params) => {
  return application.call(API.VENDORS.VENDOR_NOTE_OPTION, params);
});

const exportCsv = createAsyncThunk('vendors/exportCsv', (params) => {
  return application.call(API.CONTRACT_AWARDS.EXPORT_CSV, params);
});

const fetchTeaming = createAsyncThunk('vendors/getOrgTeaming', (params) => {
  return application.call(API.VENDORS.GET_VENDOR_TEAMING, params);
});
function onFetchTeaming(state, action) {
  return Object.assign(state, {
    teaming: action.payload.orgTeaming,
    teamingPending: false,
  });
}

const teamingPending = createAsyncThunk('vendors/teamingPending', () => {});
function onTeamingPending(state) {
  return Object.assign(state, {
    teaming: null,
    teamingPending: true,
  });
}

function onAddVendorMember(state, action) {
  Object.assign(state, { memberPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.members.items, [...state.members.items, action.payload]);
}
function onUpdateVendorMember(state, action) {
  Object.assign(state, { memberPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.members.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.members, {
      items: [...copy],
    });
  }
}

function onRemoveVendorMember(state, action) {
  Object.assign(state, { memberPending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.members.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.members, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}
function onAddVendorNote(state, action) {
  Object.assign(state, { notePending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.notes.items, [...state.notes.items, action.payload]);
}
function onUpdateVendorNote(state, action) {
  Object.assign(state, { notePending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.notes.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.notes, {
      items: [...copy],
    });
  }
}

function onRemoveVendorNote(state, action) {
  Object.assign(state, { notePending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.notes.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.notes, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}
function onAddVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [];
  copy.push(action.payload);
  Object.assign(state.naics.items, [...state.naics.items, action.payload]);
}
function onUpdateVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });
  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.naics.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    Object.assign(copy[index], action.payload);

    Object.assign(state.naics, {
      items: [...copy],
    });
  }
}

function onRemoveVendorNaics(state, action) {
  Object.assign(state, { naicsPending: false });

  if (action.payload && action.payload.hasOwnProperty('success') & !action.payload.success) return;
  const copy = [...state.naics.items];
  const index = copy.findIndex((i) => i.id === action.payload.id);
  if (index !== -1) {
    copy.splice(index, 1);
    Object.assign(state.naics, {
      items: [...copy],
      count: state.count - 1,
    });
  }
}

const agencyReportsActions = generateReportsActions({
  scope: `${SCOPE_VENDORS}/reports`,
  apiMethod: {
    GET_FUNDING: API.VENDORS.GET_FUNDING,
    GET_AWARDS_COUNT: API.VENDORS.GET_AWARDS_COUNT,
  },
  getStore: (store) => store.vendorsDetails,
});

const federalContractAwardsListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/federalContractAwards`,
  apiMethod: {
    GET_LIST: API.VENDORS.FETCH_FEDERAL_CONTRACT_AWARDS,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FEDERAL_CONTRACT_AWARDS,
  },
  getStore: (store) => store.vendorsDetails.federalContractAwards,
});

const vendorNoteMemberListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteMembers`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE_MEMBERS,
  },
  getStore: (store) => store.vendorsDetails.members,
});
const vendorNoteNotesListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteNotes`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE,
  },
  getStore: (store) => store.vendorsDetails.notes,
});

const vendorNoteNaicsListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/vendorNoteNaics`,
  apiMethod: {
    GET_LIST: API.VENDORS.GET_ALL_VENDOR_NOTE_NAICS,
  },
  getStore: (store) => store.vendorsDetails.naics,
});

const fundingAnalysisListActions = generateListActions({
  scope: `${SCOPE_VENDORS}/fundingAnalysis`,
  apiMethod: {
    GET_LIST: API.VENDORS.FETCH_FUNDING_ANALYSIS,
    EXPORT_CSV: API.FEDERAL_AGENCIES.EXPORT_FUNDING_ANALYSIS,
  },
  getStore: (store) => store.vendorsDetails.fundingAnalysis,
});

const cleanStore = createAction('vendors/cleanStore');

const fetchAsideList = createAsyncThunk('vendors/getStructureList', () => {
  return application.call(API.VENDORS.GET_VENDORS_ASIDE);
});
function onFetchAsideList(state, action) {
  return Object.assign(state, {
    asideList: action.payload,
  });
}

const getAvatarUrlByUEI = createAsyncThunk('org/getOrgAvatarByUEI', async (params) => {
  return application.call(API.VENDORS.GET_ORG_AVATAR_BY_UEI, params);
});
function onGetAvatarUrlByUEI(state, action) {
  if (action.payload) {
    Object.assign(state, {
      orgAvatarUrl: action.payload,
    });
  }
}

export const actions = {
  fetchVendor,
  addToFavorite,
  addVendorOption,
  onUpdateNote,
  exportCsv,
  fetchTeaming,
  ...agencyReportsActions,
  fundingAnalysisListActions,
  federalContractAwardsListActions,
  cleanStore,
  fetchAsideList,
  getAvatarUrlByUEI,
  vendorNoteMemberListActions,
  vendorNoteNotesListActions,
  vendorNoteNaicsListActions,
  addVendorMember,
  removeVendorMember,
  updateVendorMember,
  addVendorNote,
  removeVendorNote,
  updateVendorNote,
  addVendorNaics,
  removeVendorNaics,
  updateVendorNaics,
};

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithReportsActions(builder, agencyReportsActions, (store) => store);
  extendBuilderWithListActions(builder, fundingAnalysisListActions, (store) => store.fundingAnalysis);
  extendBuilderWithListActions(builder, federalContractAwardsListActions, (store) => store.federalContractAwards);
  extendBuilderWithListActions(builder, vendorNoteMemberListActions, (store) => store.members);
  extendBuilderWithListActions(builder, vendorNoteNotesListActions, (store) => store.notes);
  extendBuilderWithListActions(builder, vendorNoteNaicsListActions, (store) => store.naics);

  return (
    builder
      .addCase(fetchVendor.fulfilled, onItemDetails)
      .addCase(fetchVendor.pending, (state) =>
        Object.assign(state, INITIAL_STATE, { pending: true, teamingPending: true, orgAvatarUrl: null })
      )
      .addCase(fetchVendor.rejected, onItemDetailsReject)
      // Teaming
      .addCase(fetchTeaming.fulfilled, onFetchTeaming)
      .addCase(fetchTeaming.rejected, onItemDetailsReject)

      .addCase(addVendorMember.fulfilled, onAddVendorMember)
      .addCase(removeVendorMember.fulfilled, onRemoveVendorMember)
      .addCase(updateVendorMember.fulfilled, onUpdateVendorMember)
      .addCase(updateVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))
      .addCase(removeVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))
      .addCase(addVendorMember.pending, (state) => Object.assign(state, { memberPending: true }))

      .addCase(addVendorNote.fulfilled, onAddVendorNote)
      .addCase(removeVendorNote.fulfilled, onRemoveVendorNote)
      .addCase(updateVendorNote.fulfilled, onUpdateVendorNote)
      .addCase(updateVendorNote.pending, (state) => Object.assign(state, { notePending: true }))
      .addCase(removeVendorNote.pending, (state) => Object.assign(state, { notePending: true }))
      .addCase(addVendorNote.pending, (state) => Object.assign(state, { notePending: true }))

      .addCase(addVendorNaics.fulfilled, onAddVendorNaics)
      .addCase(removeVendorNaics.fulfilled, onRemoveVendorNaics)
      .addCase(updateVendorNaics.fulfilled, onUpdateVendorNaics)
      .addCase(updateVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }))
      .addCase(removeVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }))
      .addCase(addVendorNaics.pending, (state) => Object.assign(state, { naicsPending: true }))

      .addCase(fetchAsideList.fulfilled, onFetchAsideList)
      .addCase(getAvatarUrlByUEI.fulfilled, onGetAvatarUrlByUEI)
      .addCase(getAvatarUrlByUEI.pending, (state) => Object.assign(state, { orgAvatarUrl: null }))
  );
});
