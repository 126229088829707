import { connect } from 'react-redux';
import { usersActions } from '../../../../store/orgUsers';
import { authActions } from '../../../../store/auth';
import { orgActions } from '../../../../store/orgSettings';
import { actions } from '../../../../store/notifications';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
    settings: state.orgSettings,
    subscription: state.subscription,
    banner: state.notifications.banner,
    stage: state.stage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrgInfo: () => dispatch(orgActions.getOrganizationInfoAction({})),
    changeOrganization: (params) => dispatch(usersActions.changeActiveOrganization(params)),
    updateUserOrganizations: (params) => dispatch(authActions.updateUserOrganizations(params)),
    updateCurrentOrgInfo: (params) => dispatch(orgActions.updateCurrentOrgInfo(params)),
    updateInfoUser: (params) => dispatch(authActions.updateInfoUser(params)),
    updateInfoOrg: (params) => dispatch(orgActions.updateInfoOrg(params)),
    getBanner: () => dispatch(actions.getBanner()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
