import DayjsUtils from '@date-io/dayjs';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import React, { useEffect, useRef } from 'react';
import PipelineDetails from '../../../containers/Pipelines/PipelineDetails';
import useBooleanHandlers from '../../../hooks/useBooleanHandlers';
import { useMediaQueryDown } from '../../../hooks/useMediaQuery';
import SectionScrollable from '../../common/Containers/SectionScrollable';
import AppHeader from './AppHeader';
import AppMenu from './AppMenu';
import useStyles from './styles';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppMenu } from './AppMenu/hooks/useActiveGroup';
import connect from './UserMenu/connect';
import useLogout from '../../../hooks/useLogout';
import { emptyArray } from '../../../utils/arrayUtils';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { alpha as fade } from '@material-ui/core/styles/colorManipulator';
import { breakpoints, spacing } from '../../../themes/theme/common';
import mixins from '../../../themes/theme/mixins';
import overrides from '../../../themes/theme/overrides';
import typography from '../../../themes/theme/typography';
import { SUBSCRIPTION_STATUS } from '../../../utils/constants';
import Banner from '../AppLayout/Banner';
import Scripts from '../../common/Scripts';
import { useTrans } from '../../../services/i18n';
import ExpiredSubscriptionModal from './ExpiredSubscriptionModal';

const APP_SUPPORT_LIMITED_BEACON = 'be7264ed-bcdc-4783-b9b0-fbf6bb4ca57a';
const APP_SUPPORT_STANDARD_BEACON = '6b91b113-288f-4fdd-92c8-e9b3c4824e68';
const APP_SUPPORT_FULL_BEACON = '1d520255-59cd-4d1a-bed7-82787205ecba';

const TIERS = {
  LIMITED: 'limited',
  ADVANTAGE: 'advantage',
  PRO: 'pro',
  FREE_TRIAL: 'freeTrial',
  SAP_ADVANTAGE: 'sapAdvantage',
  APP_MAX: 'appMax',
  PROCUREMENT_PRO: 'procurementPro',
};

const AppLayout = ({ children, settings, subscription, authUser, stage }) => {
  const styles = useStyles();
  const isSmallScreen = useMediaQueryDown('sm');
  const [openDrawer, setDrawerOpen, setDrawerClose] = useBooleanHandlers(true);
  const location = useLocation();
  const { menu } = useAppMenu();
  const logout = useLogout();
  const history = useHistory();
  const hasBeenInitialized = useRef(false);
  const { trans } = useTrans();

  const firstColor = settings?.colorsBranding?.firstColor ? settings?.colorsBranding?.firstColor : '#2445a7';
  const secondColor = settings?.colorsBranding?.secondColor ? settings?.colorsBranding?.secondColor : '#f32445';
  const thirdColor = settings?.colorsBranding?.thirdColor ? settings?.colorsBranding?.thirdColor : '#4e5978';
  const fourthColor = settings?.colorsBranding?.fourthColor ? settings?.colorsBranding?.fourthColor : '#888fa9';
  const fifthColor = settings?.colorsBranding?.fifthColor ? settings?.colorsBranding?.fifthColor : '#ffffff';

  useEffect(() => {
    if (isSmallScreen) {
      setDrawerClose();
    } else {
      setDrawerOpen();
    }
  }, [isSmallScreen, setDrawerClose, setDrawerOpen]);

  useEffect(() => {
    if (authUser.preferredPlan) {
      const organization = authUser?.organizations[0];
      console.log('🚀 ~ file: index.js:69 ~ useEffect ~ organization:', organization);
      window.location = organization.subscriptionId
        ? `/subscription-success/${organization.subscriptionId}`
        : `/checkout?plan=${authUser.preferredPlan}`;
      return;
    }

    let menuItems = menu.map((i) => i.items);
    menuItems = [].concat.apply([], menuItems);

    if (emptyArray(settings.permissions)) {
      logout();
      return;
    }

    let permissions = settings.permissions.map((i) => i.submodules);
    permissions = [].concat.apply([], permissions);

    if (!settings.active && location.pathname !== '/organization-deactivated') {
      history.replace('/organization-deactivated');
    }

    const exists = menuItems.find((i) => i.path === location.pathname);

    if (exists && exists.keyPermission) {
      const canView = permissions.includes(exists.keyPermission);
      if (!canView) {
        const firstRoute = menuItems.find((i) => i.keyPermission && permissions.includes(i.keyPermission));
        if (firstRoute) {
          history.push(firstRoute.path);
        } else {
          logout();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /***
   * Assign to the Beacon the init value according to the user plan
   */
  useEffect(() => {
    if (hasBeenInitialized.current) return;

    hasBeenInitialized.current = true;
    const script = document.createElement('script');
    if (authUser && authUser.accessToken) {
      window.Beacon('identify', {
        email: authUser.email,
        name: authUser.name,
      });
      if (subscription?.data?.currentPlan === TIERS.LIMITED || subscription?.data?.currentPlan === TIERS.FREE_TRIAL) {
        script.src = window.Beacon('init', APP_SUPPORT_LIMITED_BEACON);
      } else if (
        subscription?.data?.currentPlan === TIERS.ADVANTAGE ||
        subscription?.data?.currentPlan === TIERS.PRO ||
        subscription?.data?.currentPlan === TIERS.SAP_ADVANTAGE
      ) {
        script.src = window.Beacon('init', APP_SUPPORT_STANDARD_BEACON);
      } else if ([TIERS.PROCUREMENT_PRO, TIERS.APP_MAX].includes(subscription?.data?.currentPlan || '')) {
        script.src = window.Beacon('init', APP_SUPPORT_FULL_BEACON);
      }
      script.async = true;
      document.body.appendChild(script);
    }
  }, [subscription, authUser]);

  const theme = createTheme({
    palette: {
      type: 'light',
      common: {
        white: fifthColor,
        black: '#000',
      },
      primary: { main: firstColor, light: '#52cbff', dark: '#233a7e', fade: fade(firstColor, 0.2), tabs: firstColor }, //main = principal
      secondary: { main: secondColor, dark: '#e3212e', fade: fade('#f32445', 0.2) }, // main = secundario
      success: { main: '#17b471', dark: '#11ca7b', fade: fade('#17b471', 0.2) },
      error: { main: '#ff5225', fade: fade('#ff5225', 0.2) },
      violet: { main: '#9576ff' },
      warning: { main: '#faa900', light: '#ffac00', fade: fade('#faa900', 0.2) },
      info: { main: '#526fff', fade: fade('#526fff', 0.2) },
      pipeline: { main: '#29365b', light: '#2e3c63', dark: '#20283e' },
      gray: {
        main: thirdColor, // main = tertiary icons
        light: fourthColor, // light = letters
        active: '#b2b8cf',
        paperActive: '#dce0e8',
        divider: '#eaebef',
        border: '#edeef1',
        background: '#f1f3f9',
        fade: fade(thirdColor, 0.1), //main = tertiary icons
        disabled: '#999999',
      },
      text: {
        primary: '#000',
        secondary: '#cac9cf',
        disabled: '#6b6a6f',
        hint: fourthColor, // light = letters
        success: '#17b471',
        placeholder: '#b2b8cf',
      },
      background: {
        paper: fifthColor,
        paperSecondary: '#f1f3f9',
        default: '#fff',
        input: '#f1f3f9',
      },
      action: {
        light: 'rgba(255,255,255,0.8)',
      },
      divider: '#b2b8cf',
    },
    direction: 'ltr',
    shape: {
      borderRadius: 16,
      borderRadiusAlt: 12,
    },
    breakpoints: { values: breakpoints.values },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1048,
      appBar: 1049,
      tooltip: 1500,
      drawer: 1600,
      modal: 1700,
      snackbar: 1800,
    },
    typography,
    overrides,
    mixins,
    spacing,
  });

  const planTier = React.useMemo(() => {
    return subscription?.data?.currentPlan;
  }, [subscription]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div className={styles.root}>
        <ThemeProvider theme={theme}>
          <AppMenu openDrawer={openDrawer} setDrawerOpen={setDrawerOpen} setDrawerClose={setDrawerClose} />
          <section className={styles.content}>
            <Banner></Banner>
            <SectionScrollable
              bgColor="paperSecondary"
              scrollableID={'scrollable'}
              TopFixed={
                <AppHeader openDrawer={openDrawer} setDrawerClose={setDrawerClose} setDrawerOpen={setDrawerOpen} />
              }
            >
              {children}
            </SectionScrollable>
          </section>

          <PipelineDetails />
        </ThemeProvider>
      </div>

      <ExpiredSubscriptionModal subscription={subscription?.data} user={authUser} />
      <Scripts authUser={authUser} orgSettings={{ ...settings, planTier: trans(planTier) }} stage={stage} />
    </MuiPickersUtilsProvider>
  );
};

export default connect(AppLayout);
