import { connect, batch } from 'react-redux';
import {
  generateCheckItemsCallbacks,
  generateListCallbacks,
  generateNotInterestedCallbacks,
} from '../../../../store/helpers/listActionsHelpers';
import { actions } from '../../../../store/notifications';
import { usersActions } from '../../../../store/orgUsers';
import { update } from '../../../../store/auth';

function mapStateToProps(store) {
  const notifications = store.notifications;
  const checkedLength = Array.isArray(notifications.checkedItems) ? notifications.checkedItems.length : 0;
  const itemsLength = Array.isArray(notifications.items) ? notifications.items.length : 0;

  return {
    notifications,
    isSomeChecked: checkedLength > 0,
    isAllChecked: itemsLength > 0 && checkedLength === itemsLength,
    settings: notifications.settings,
    settingsPending: notifications.settingsPending,
    subscription: store.subscription,
    orgSettingsId: store.orgSettings,
    authUser: store.auth || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    ...generateNotInterestedCallbacks(dispatch, actions),
    ...generateCheckItemsCallbacks(dispatch, actions),

    onInit: async (params) => {
      batch(() => {
        dispatch(actions.getSettings());
        dispatch(actions.removeAllItems());
        dispatch(actions.setParams(params));
      });
      return dispatch(actions.fetchList());
    },
    onRefreshList: (params) => {
      batch(() => dispatch(actions.setParams(params)));
      return dispatch(actions.fetchList());
    },
    setFetchWeek: (value) => dispatch(actions.setFetchWeek(value)),

    onCheckRead: () => dispatch(actions.checkAllItems({ filterCB: 'filterRead' })),
    onCheckUnread: () => dispatch(actions.checkAllItems({ filterCB: 'filterUnread' })),
    onSetSettings: (params) => dispatch(actions.setSettings(params)),
    getUnread: () => dispatch(actions.getUnread()),

    onMarkAsRead: async (value) => {
      await dispatch(actions.markAsRead(value));
      await dispatch(actions.getUnread());
      return dispatch(actions.fetchList());
    },
    onMarkAsReadSingle: async (value) => {
      await dispatch(actions.markAsReadSingle(value));
      await dispatch(actions.getUnread());
      return dispatch(actions.fetchList());
    },
    onMarkAsTrashed: async () => {
      await dispatch(actions.markAsTrashed());
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onMarkAsRestored: async () => {
      await dispatch(actions.markAsRestored());
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onDelete: async () => {
      await dispatch(actions.remove());
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onMarkAllTrash: async (params) => {
      await dispatch(actions.markAllTrash(params));
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onRestoreAll: async (params) => {
      await dispatch(actions.restoreAll(params));
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onDeleteAll: async (params) => {
      await dispatch(actions.deleteAll(params));
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    changeOrganization: (params) => dispatch(usersActions.changeActiveOrganization(params)),
    updateEmailNotificationSettings: (data) => {
      const {
        consolidateNotificationNumber,
        consolidateNotificationSelect,
        pursuitNotices,
        savedSearchNotices,
        teamUpdates,
        taskReminders,
        pursuitDailyRecap,
        savedSearchDailyRecap,
        teamUpdatesDailyRecap,
        taskRemindersDailyRecap,
      } = data;
      return dispatch(
        update({
          consolidateNotificationNumber,
          consolidateNotificationSelect,
          pursuitNotices,
          savedSearchNotices,
          teamUpdates,
          taskReminders,
          pursuitDailyRecap,
          savedSearchDailyRecap,
          teamUpdatesDailyRecap,
          taskRemindersDailyRecap,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
