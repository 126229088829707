import { APP_MENU } from '../../../../../constants';
import { matchPath, useHistory } from 'react-router-dom';
import React from 'react';

export const useAppMenu = () => {
  const history = useHistory();

  const [activeGroup, setActiveGroup] = React.useState(-1);

  React.useLayoutEffect(() => {
    setActiveGroup(-1);
  }, [history.location.pathname]);

  const oneMatches = (group) => {
    return group.items.some((item) => {
      return matchPath(history.location.pathname, {
        path: item.path,
      });
    });
  };

  return {
    menu: React.useMemo(() => {
      return APP_MENU.map((group, idx) => {
        const groupIsActive = activeGroup > -1 ? activeGroup === idx : oneMatches(group);
        return {
          ...group,
          active: groupIsActive,
        };
      });
    }, [activeGroup, history.location.pathname]),
    setActive(idx) {
      setActiveGroup(idx);
    },
  };
};
